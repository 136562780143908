export default class Aprovador {

  constructor(codpesavd, nompesavd, stadcacalmed, notaFinal){
    this.codpesavd = codpesavd
    this.nompesavd = nompesavd
    this.stadcacalmed = stadcacalmed
    this.notaFinal = notaFinal
  }

  static fromJSON(json){
    return new Aprovador(
      json.codpesavd,
      json.nompesavd,
      json.stadcacalmed,
      json.notaFinal
    )
  }


}