import { PortalServico } from "@/utils";
import VertenteProServ from "../modelos/VertenteProServ";
import SituacaoProServ from "../modelos/SituacaoProServ";

const API_PREFFIX = '/proserv'

export default class ProServEstruturaServico extends PortalServico {

  listarSituacoes(vtesolprs){
    const self = this;
    return self.get(`${API_PREFFIX}/estrutura/situacoes/?vtesolprs=${vtesolprs}`)
                .then(lista => lista.map(item => SituacaoProServ.fromJSON(item)));
  }

  listarVertentes(){
    const self = this
    return self.get(`${API_PREFFIX}/estrutura/vertentes/`)
                .then(lista => lista.map(item => VertenteProServ.fromJSON(item)));
  }

}