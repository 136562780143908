import { PortalServico } from "@/utils";
import Aprovacao from "./modelos/Aprovacao";

const API_PREFFIX = '/proserv'

export default class ProServAprovacaoServico extends PortalServico {


  listarAprovacoes(vtesolprs, tipsitprs){
    const self = this;
    return self.get(`${API_PREFFIX}/aprovacoes/${vtesolprs}/${tipsitprs}`)
                .then(resultado => resultado.map(item => Aprovacao.fromJSON(item)))
  }
  
  desconsiderarAprovacao(vtesolprs, anosolprs, codsolprs, codpesavd, tipsitprs){
    const self = this;
    return self.patch(`${API_PREFFIX}/aprovacoes/${vtesolprs}/${tipsitprs}/${codsolprs}/${anosolprs}/${codpesavd}`)
              .then(resultado => Aprovacao.fromJSON(resultado))
  }

  aprovarSolicitacoes(vtesolprs, tipsitprs, solicitacoes) {
    const self = this;
    return self.put(`${API_PREFFIX}/aprovacoes/${vtesolprs}/${tipsitprs}`, solicitacoes)
  }

  finalizarAprovacoes(vtesolprs, tipsitprs) {
    const self = this;
    return self.patch(`${API_PREFFIX}/aprovacoes/${vtesolprs}/${tipsitprs}`)
  }

}