<template>
  <uspCartao 
    :titulo="titulo"
  >
    <template #corpo>
      <uspSkeleton v-if="carregando" />
      <div
        v-if="!carregando && solicitacao"
        class="container-fluid"
      >
        <div class="list-group">
          <div 
            v-for="and in andamentos"
            :key="'andamento-'+and.numseqandprs"
            class="list-group-item andamento-item"
          >
            <span class="numero">
              {{ and.numseqandprs }}
            </span>
            <span class="data">
              {{ and.dtaandsolprs }}
            </span>
            <span class="situacao">
              {{ and.sitprsand.dsctipsitprs }}
            </span>
            <span class="responsavel">
              {{ and.pesgrcand }}
            </span>
            <span class="descricao">
              {{ and.txtand }}
            </span>
          </div>
        </div>
      </div>
    </template>
  </uspCartao>
</template>
<script>
import { Actions, Getters } from '@/proserv/store/types';
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'ProServAndamentosCartao',

    props: {
      carregando: {
        type: Boolean,
        default: false
      },
      solicitacao: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
      }
    },

    computed: {
      ...mapGetters({
        andamentos: Getters.PROSERV.ANDAMENTOS
      }),
      titulo(){
        return this.solicitacao ? 'Andamentos' : 'Carregando...'
      },
    },

    watch: {
      solicitacao(){
        if(this.solicitacao){
          this.carregarAndamentos(this.solicitacao)
        }
      }
    },

    methods: {
      ...mapActions({
        carregarAndamentos: Actions.PROSERV.CARREGAR_ANDAMENTOS
      })
    }

}
</script>

<style lang="scss" scoped>

  div.andamento-item {
    display: flex;
    flex-flow: row wrap;
    align-items: center;

    .numero {
      flex: 0;
      font-weight: 700;
      color: #1094ab;
      margin-right: .4rem;
    }

    .data {
      flex: 0 0 12rem;
      text-align: center;
      color: #696969;
      margin: 0 .4rem;
    }

    .situacao {
      flex: 0 0 15rem;
      color: #696969;
      margin: 0 .4rem;
      font-style: italic;
    }

    .responsavel {
      font-weight: 900;
      margin: 0 .4rem;
    }

    .descricao {
      flex: 1;
      margin-left: .4rem;
    }

  }

</style>