<!-- eslint-disable vue/no-v-html -->
<template>
  <uspCartao 
    :titulo="titulo"
  >
    <template #corpo>
      <uspSkeleton v-if="carregando" />
      <div class="editor-container">
        <uspAlerta
          v-if="varianteAlerta"
          :variante="varianteAlerta"
        >
          <em 
            v-if="salvando"
            class="fas fa-2x fa-spinner fa-pulse" 
          />
          {{ varianteTexto }}
          <div 
            v-if="mensagem"
            class="progress"
          >
            <div 
              class="progress-bar progress-bar-striped bg-success" 
              role="progressbar"
              :style="'width: '+progresso+'%'"
              :aria-valuenow="progresso" 
              aria-valuemin="0" 
              aria-valuemax="100"
            />
          </div>
        </uspAlerta>
        <div class="form-recurso form-row">
          <span class="label">Acatar Recurso?</span>
          <div class="checks">
            <div class="form-check form-check-inline">
              <input 
                id="parecer-recurso-aprovado-nao" 
                v-model="aprovado"
                class="form-check-input" 
                type="radio"
                name="aprovado" 
                value="NAO"
              >
              <label 
                class="form-check-label" 
                for="parecer-recurso-aprovado-nao"
              >
                Não
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input 
                id="parecer-recurso-aprovado-sim"
                v-model="aprovado"
                class="form-check-input" 
                type="radio" 
                name="aprovado" 
                value="SIM"
              >
              <label 
                class="form-check-label" 
                for="parecer-recurso-aprovado-sim"
              >
                Sim
              </label>
            </div>
          </div>
          <!--
            <div class="select-solicitacao">
            <label for="parecer-recurso-solicitacao">
              Substituir por
            </label>
            <select 
              id="parecer-recurso-solicitacao"
              v-model="codsolprsrcs"
              class="form-control"
              :disabled="aprovado !== 'SIM'"
            >
              <option value="0">
                {{ solicitacaoText }}
              </option>
              <option
                v-for="sol in solicitacoes"
                :key="'ori-'+sol.codsolprs"
                :value="sol.codsolprs"
              >
                Solicitação #{{ sol.codsolprs }}/{{ sol.anosolprs }}
              </option>
            </select>
          </div>-->
        </div>
        <div class="form-row form-group">
          <textarea 
            id="proserv-recurso-pcercs"
            v-model="pcercs"
            name="texto"
            :maxlength="total"
            class="form-control"
            rows="10"
            aria-label="Parecer do recurso"
          />
        </div>
      </div>
    </template>
    <template #rodape>
      <div class="acoes">
        <p class="contador">
          Contagem de Caracteres: 
          <span class="atual">
            {{ caracteres }}
          </span>
          de
          <span class="total">
            {{ total }}
          </span>
        </p>
        <uspBotao
          icone="fas fa-save"
          texto="Salvar e Enviar"
          :carregando="salvando"
          texto-carregando="Enviando..."
          @clicar="salvar"
        />
      </div>
    </template>
  </uspCartao>
</template>
<script>
import ProServServico from '@/proserv/dominio/modelos/ProServServico';


export default {
    name: 'ProServAvaliarRecursoCartao',

    props: {
      carregando: {
        type: Boolean,
        default: true
      },
      solicitacao: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        api: ProServServico.build({}),
        aprovado: '',
        carregandoSols: false,
        erro: '',
        salvando: false,
        mensagem: '',
        pcercs: this.solicitacao.detalhes.pcercs,
        total: 1500,
        contador: 0,
        codsolprsrcs: 0,
        solicitacoes: [],
        progresso: 0
      }
    },

    computed: {
      caracteres(){
        return this.pcercs?.replace(/\s/g, '').length ?? 0;
      },
      solicitacaoText(){
        if(this.carregandoSols) return 'Carregando solicitações...';
        return this.aprovado === 'SIM' ? '- Selecione a Solicitação -' : '- Apenas para resposta SIM -'
      },
      titulo(){
        return this.solicitacao ? 'Parecer sobre o Recurso' : 'Carregando...'
      },
      solicitacaoOri(){
        return this.solicitacoes.find(s => s.codsolprs == this.codsolprsrcs)
      },
      varianteAlerta(){
        if (this.erro) return 'perigo'
        else if(this.mensagem) return 'sucesso'
        else if(this.salvando) return 'primario'
        else return ''
      },
      varianteTexto(){
        if(this.erro) return this.erro
        else if(this.mensagem) return this.mensagem
        else if(this.salvando) return 'Salvando conteúdo...'
        return ''
      }
    },

    watch:{
      pcercs(){
        if(this.pcercs){
          this.erro = ''
        }
      }
    },

    mounted(){
      const self = this;
      self.carregandoSols = true;
      self.api.listarSolicitacoes()
        .then(lista => self.solicitacoes = lista.filter(s => s.sitprsatl.tipsitprs === 'APROVADO'))
        .catch(error => self.erro = error.detail)
        .finally(() => self.carregandoSols = false);
    },

    methods: {
      mostrarSucesso(){
        const self = this
        self.progresso = 0
        self.mensagem = 'Texto salvo com sucesso!'
        let interval = setInterval(() => {
          self.progresso += 25
          if(self.progresso > 100){
            self.mensagem = ''
            clearInterval(interval)
          }
        }, 300)
      },
      salvar(){
        const self = this
        self.erro = '';
        if(!this.aprovado){
          self.erro = `Você deve escolher se recurso será acatado ou não!`
          return
        }
        // if(this.aprovado === 'SIM' && this.codsolprsrcs == 0){
        //   self.erro = `Você deve escolher a solicitação que será trocada nesse caso!`
        //   return
        // }
        if(!this.pcercs){
          self.erro = `O campo de parecer é obrigatório!`
          return
        }
        if(this.caracteres > this.total){
          self.erro = `O campo está com mais do que os ${this.total} caracteres permitidos!`
          return
        }
        self.salvando = true
        self.api.salvarRecurso(
          self.solicitacao.anosolprs, 
          self.solicitacao.codsolprs,
          {
            aprovado: self.aprovado === 'SIM',
            pcercs: self.pcercs,
            codsolprsori: self.codsolprsrcs,
            anosolprsori: self.solicitacao.anosolprs
          }
        ).then(() => self.$emit('sucesso'))
         .catch(erro => self.erro = erro)
         .finally(() => self.salvando = false)
      }
    }

}
</script>

<style lang="scss" scoped>

  .form-recurso {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 1rem;

    span.label {
      color: #1094ab;
      font-weight: 700;
      margin: 0 .75rem;
    }

    .select-solicitacao {
      align-items: center;
      display: flex;
      background-color: #F0F0F0;
      border-radius: 3px;
      flex-flow: row nowrap;
      flex: 1;
      padding: 0.3rem 0.5rem;

      label {
        flex-basis: 10em;
        margin: 0;
      }
    }

  }

  div.acoes {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;

    p.contador {
      font-weight: 500;
      margin: 0 .75rem;
      span.atual, span.total {
        color: #1094ab;
      }
    }
  }

</style>