<!-- eslint-disable vue/no-v-html -->
<template>
  <uspCartao 
    :titulo="titulo"
  >
    <template #corpo>
      <uspSkeleton v-if="carregando" />
      <div class="editor-container">
        <uspAlerta
          :variante="varianteAlerta"
        >
          <em 
            v-if="salvando"
            class="fas fa-2x fa-spinner fa-pulse" 
          />
          {{ varianteTexto }}
          <div 
            v-if="mensagem"
            class="progress"
          >
            <div 
              class="progress-bar progress-bar-striped bg-success" 
              role="progressbar"
              :style="'width: '+progresso+'%'"
              :aria-valuenow="progresso" 
              aria-valuemin="0" 
              aria-valuemax="100"
            />
          </div>
        </uspAlerta>
        <div 
          class="form-row form-group"
        >
          <ckeditor 
            ref="editor"
            v-model="texto" 
            :config="editorConfig"
            tag-name="textarea"
            class="ckeditor-div"
            @focus="limpar"
            @blur="salvar"
          />
        </div>
        <div class="contador">
          <p>
            Contagem de Caracteres: 
            <span class="atual">
              {{ caracteres }}
            </span>
            de
            <span class="total">
              {{ total }}
            </span>
          </p>
        </div>
      </div>
    </template>
  </uspCartao>
</template>
<script>
import { Actions } from '@/proserv/store/types'
import { mapActions } from 'vuex'

export default {
    name: 'ProServEditorCartao',

    props: {
      carregando: {
        type: Boolean,
        default: true
      },
      solicitacao: {
        type: Object,
        required: true
      },
      propriedade: {
        type: String,
        required: true
      },
      etiqueta: {
        type: String,
        required: true
      },
      total: {
        type: Number,
        default: 10000
      }
    },

    data() {
      return {
        erro: '',
        salvando: false,
        mensagem: '',
        valor: '',
        contador: 0,
        progresso: 0,
        editorConfig: {
          removeButtons: 'Anchor,HorizontalRule,Image,Table,Format,Styles',
          coreStyles_bold: { element: 'b', overrides: 'strong' },
          coreStyles_italic: { element: 'i', overrides: 'em' },
          coreStyles_strike: {
            element: 'font',
            attributes: { 'font style' :'text-decoration: line-through' },
            overrides: 'strike'
          }
        }
      }
    },

    computed: {
      caracteres(){
        let div = document.createElement('div')
        div.innerHTML = this.valor || this.texto;
        return div.innerText.replace(/\s/g, '').length;
      },
      texto:{
        get() {
          return this.valor || this.solicitacao.detalhes ? this.solicitacao.detalhes[this.propriedade] : ''
        },
        set(t){
          this.valor = t
        }
      },
      titulo(){
        return this.solicitacao ? this.etiqueta : 'Carregando...'
      },
      varianteAlerta(){
        if (this.erro) return 'perigo'
        else if(this.mensagem) return 'sucesso'
        else if(this.salvando) return 'primario'
        else return 'secundario'
      },
      varianteTexto(){
        if(this.erro) return this.erro
        else if(this.mensagem) return this.mensagem
        else if(this.salvando) return 'Salvando conteúdo...'
        return 'O campo abaixo possui recurso de salvamento automático'
      }
    },

    methods: {
      ...mapActions({
        salvarTexto: Actions.PROSERV.SALVAR_DETALHES
      }),
      mostrarSucesso(){
        const self = this
        self.progresso = 0
        self.mensagem = 'Texto salvo com sucesso!'
        let interval = setInterval(() => {
          self.progresso += 25
          if(self.progresso > 100){
            self.mensagem = ''
            clearInterval(interval)
          }
        }, 300)
      },
      limpar(eve){
        eve.editor.resetDirty()
      },
      salvar(eve){
        const self = this
        if(eve.editor.checkDirty()){
          self.erro = '';
          if(this.caracteres > this.total){
            self.erro = `O campo está com mais do que os ${this.total} caracteres permitidos!`
            return
          }
          self.salvando = true
          self.salvarTexto({
            solicitacao: this.solicitacao,
            texto: this.valor,
            propriedade: this.propriedade
          })
          .then(() => self.mostrarSucesso())
          .catch(erro => self.erro = erro)
          .finally(() => self.salvando = false)
        }
      }
    }

}
</script>

<style lang="scss" scoped>

  div.editor-container {
    display: flex;
    flex-flow: column nowrap;
    div.ckeditor-div {
      width: 100%;
    }

    div.contador {
      align-self: flex-end;
      font-weight: 500;

      span.atual, span.total {
        color: #1094ab;
      }
    }
  }

</style>