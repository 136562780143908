<template>
  <uspCartao 
    :titulo="titulo"
  >
    <template #corpo>
      <uspSkeleton v-if="carregando" />
      <div
        v-else
        class="upload-container"
      >
        <uspAlerta
          variante="secundario"
        >
          Cada documento pode ter, no máximo, 10 Mb.
          <br>
          Os documentos devem estar em um dos seguintes formatos: {{ formatos }}.
        </uspAlerta>
        <div 
          v-if="!carregando && arquivos.length === 0"
          class="d-flex justify-content-center align-items-center flex-column aviso-vazio"
        >
          <img 
            src="/imgs/usp-tarefas.svg"
            alt="Ilustração de uma pilha de tarefas concluídas"
          >
          <p class="font-weight-bold text-center mt-5">
            &nbsp; Não há arquivos anexados!
          </p>
        </div>
        <div class="list-group">
          <div 
            v-for="arquivo in arquivos"
            :key="'arquivo_'+arquivo.numseqarqprs"
            class="list-group-item arquivo"
          >
            <span class="nome-arquivo">
              {{ arquivo.nomarqdla }}
            </span>
            <span class="tamanho-arquivo">
              {{ arquivo.getTamanho() }}
            </span>
            <div class="acoes">
              <uspBotao
                variante="secundario"
                texto="Visualizar Doc."
                icone="fas fa-file-pdf"
                @clicar="visualizar(arquivo)"
              />
              <uspBotao
                v-if="solicitacao.permissoes?.alterar"
                variante="perigo"
                texto="Remover Doc."
                icone="fas fa-times"
                :carregando="removendo"
                texto-carregando="Removendo..."
                @clicar="remover(arquivo)"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #rodape>
      <div 
        v-if="solicitacao.permissoes?.alterar"
        class="acoes-rodape"
      >
        <input 
          v-show="false"
          id="arquivo-upload"
          ref="arquivo"
          name="arquivo"
          type="file"
          :accept="formatos"
          @change="anexar"
        >
        <uspAlerta 
          v-if="erroUpload"
          variante="perigo"
        >
          {{ erroUpload }}
        </uspAlerta>
        <uspBotao
          type="button"
          :carregando="salvando"
          texto-carregando="Anexando..."
          texto="Anexar Doc."
          icone="fas fa-paperclip"
          @clicar="$refs.arquivo.click()"
        />
      </div>
    </template>
  </uspCartao>
</template>
<script>
import { Actions, Getters } from '@/proserv/store/types';
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'ProServArquivosCartao',

    props: {
      solicitacao: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        carregando: false,
        erroUpload: '',
        formatos: '.pdf,.xls,.xlsx,.jpg,.jpeg,.png',
        removendo: false,
        salvando: false,
      }
    },

    computed: {
      ...mapGetters({
        arquivos: Getters.PROSERV.ARQUIVOS
      }),
      titulo(){
        return this.solicitacao ? 'Arquivos da Solicitação' : 'Carregando...'
      },
    },

    mounted(){
      const self = this
      self.carregando = true
      self.carregarArquivos({
        anosolprs: self.$route.params.anosolprs,
        codsolprs: self.$route.params.codsolprs
      }).finally(() => {
        self.carregando = false
      })
    },

    methods: {
      ...mapActions({
        carregarArquivos: Actions.PROSERV.CARREGAR_ARQUIVOS,
        anexarArquivo: Actions.PROSERV.SALVAR_ARQUIVO,
        removerArquivo: Actions.PROSERV.REMOVER_ARQUIVO,
        obterArquivo: Actions.PROSERV.OBTER_ARQUIVO
      }),
      anexar(eve){
        const self = this
        const arquivo = eve.target.files[0]
        if(self.validar(arquivo)){
          self.salvando = true
          self.anexarArquivo({
            solicitacao: self.solicitacao,
            arquivo
          }).finally(() => self.salvando = false)
        }
      },
      converterBytesMega(tamanho){
        if (tamanho === 0) return 0;
        const k = 1024;
        return parseFloat((tamanho / Math.pow(k, 2)).toFixed(2))
      },
      validar(arquivo){
        const self = this
        let tamanho = self.converterBytesMega(arquivo.size)
        if(tamanho > 10){
          self.erroUpload = `Arquivos tem um limte de 10MB de tamanho (anexado possui ${tamanho}MB)`
          return false
        }
        return true
      },
      remover(arquivo){
        const self = this
        if(confirm(`Deseja mesmo remover o arquivo "${arquivo.nomarqdla}"?`)){
          self.removendo = true
          self.removerArquivo({ arquivo, solicitacao: self.solicitacao })
            .finally(() => self.removendo = false)
        }
      },
      visualizar(arquivo){
        const self = this
        self.obterArquivo({
          numseqarqprs: arquivo.numseqarqprs,
          anosolprs: self.$route.params.anosolprs,
          codsolprs: self.$route.params.codsolprs
        })
        .then(response => {
          const blob = new Blob([response])
          const url = URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          link.target = '_blank'
          link.download = arquivo.nomarqdla
          link.click()
          URL.revokeObjectURL(link.href)
        }).catch(console.error)
      }
    }

}
</script>

<style lang="scss" scoped>

  div.list-group-item.arquivo {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;

    span.nome-arquivo {
      flex: 1 0 35%;
    }

    span.tamanho-arquivo {
      flex: 1 0 15%;
    }

    label {
      font-weight: 700;
      color: #1094ab;
    }
  }

  div.acoes-rodape {
    align-items: flex-start;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    button {
      margin-left: auto;
    }
  }

  @media (max-width: 576px) { 
    div.acoes-rodape {
      align-items: stretch;
      flex-flow: column nowrap;
    }
  }

</style>