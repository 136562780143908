<template>
  <section>
    <div class="container-fluid">
      <ProServDadosCartao
        :solicitacao="solicitacao"
        :carregando="carregando"
        @enviar="recarregar"
      />
      <ProServEditorCartao
        v-if="solicitacao.permissoes?.alterar"
        :solicitacao="solicitacao"
        :carregando="carregando"
        propriedade="jussol"
        etiqueta="Justificativa"
      />
      <ProServEditorCartao
        v-if="solicitacao.permissoes?.alterar"
        :solicitacao="solicitacao"
        :carregando="carregando"
        propriedade="etrsol"
        etiqueta="Estrutura"
      />
      <ProServArquivosCartao
        v-if="solicitacao.permissoes?.alterar"
        :solicitacao="solicitacao"
        :carregando="carregando"
        etiqueta="Arquivo"
      />
      <ProServDetalhesCartao 
        v-else
        :solicitacao="solicitacao"
        :carregando="carregando"
      />
      <ProServRecursoCartao
        v-if="solicitacao.permissoes?.recurso"
        :solicitacao="solicitacao"
        :carregando="carregando"
        @sucesso="recarregar()"
      />
      <ProServAvaliarRecursoCartao 
        v-if="solicitacao.permissoes?.recavaliar"
        :solicitacao="solicitacao"
        :carregando="carregando"
        @sucesso="recarregar()"
      />
      <ProServAvaliadoresCartao 
        v-for="situacao in situacoesAvaliadores"
        :key="'avaliador-'+situacao.tipsitprs"
        :solicitacao="solicitacao"
        :carregando="carregando"
        :situacao="situacao"
        :leitura="!solicitacao.permissoes.gerenciar"
      />
      <ProServAvaliacaoCartao 
        v-for="situacao in situacoesAvaliacao"
        :key="'avaliacao-'+situacao.tipsitprs"
        :solicitacao="solicitacao"
        :carregando="carregando"
        :situacao="situacao"
      />
      <ProServEditorCartao
        v-if="solicitacao.permissoes?.justificar"
        :solicitacao="solicitacao"
        :carregando="carregando"
        :total="1000"
        propriedade="pcenaoaprsol"
        etiqueta="Parecer de Não Contemplado"
      />
      <ProServAndamentosCartao 
        :solicitacao="solicitacao"
        :carregando="carregando"
      />
    </div>
  </section>
</template>

<script>
import { Actions, Getters } from '@/proserv/store/types';
import { mapActions, mapGetters } from 'vuex';

import ProServAndamentosCartao from '../componentes/detalhes/ProServAndamentosCartao.vue'
import ProServArquivosCartao from '../componentes/detalhes/ProServArquivosCartao.vue'
import ProServDadosCartao from '../componentes/detalhes/ProServDadosCartao.vue'
import ProServEditorCartao from '../componentes/detalhes/ProServEditorCartao.vue'
import ProServDetalhesCartao from '../componentes/detalhes/ProServDetalhesCartao.vue';
import ProServAvaliadoresCartao from '../componentes/detalhes/ProServAvaliadoresCartao.vue';
import ProServAvaliacaoCartao from '../componentes/detalhes/ProServAvaliacaoCartao.vue';
import ProServRecursoCartao from '../componentes/detalhes/ProServRecursoCartao.vue';
import ProServAvaliarRecursoCartao from '../componentes/detalhes/ProServAvaliarRecursoCartao.vue';

  export default {
    name: 'ProServDetalhes',

    components: { 
      ProServAvaliadoresCartao, 
      ProServAvaliacaoCartao,
      ProServAndamentosCartao,
      ProServArquivosCartao, 
      ProServDadosCartao,
      ProServDetalhesCartao,
      ProServEditorCartao,
      ProServRecursoCartao,
      ProServAvaliarRecursoCartao
    },

    data(){
      return {
        carregando: false,
        anosolprs: this.$route.params.anosolprs,
        codsolprs: this.$route.params.codsolprs,
      }
    },

    computed: {
      ...mapGetters({
        solicitacao: Getters.PROSERV.SOLICITACAO
      }),
      situacoesAvaliadores(){
        if(!this.solicitacao.permissoes?.gerenciar && !this.solicitacao.permissoes?.veravaliacao) return [];
        else if(this.solicitacao.vtesolprs.valor === 'E') return [{
          tipsitprs: 'AVAL_PRG', titulo: 'Avaliadores da Solicitação'
        }]
        else if(this.solicitacao.vtesolprs.valor === 'C') return [{
          tipsitprs: 'AVAL_PRCEU', titulo: 'Avaliadores da Solicitação'
        }]
        else if(this.solicitacao.vtesolprs.valor === 'P') return [
          { tipsitprs: 'AVAL_PRPI', titulo: 'Avaliadores de 1ª Fase'},
          { tipsitprs: 'AVAL_PRPI2', titulo: 'Avaliadores de 2ª Fase'}
        ]
        else return [];
      },
      situacoesAvaliacao(){
        if(!this.solicitacao?.permissoes?.avaliar) return [];
        else if(this.solicitacao.vtesolprs.valor === 'E') return [{
          tipsitprs: 'AVAL_PRG', titulo: 'Avaliação da Solicitação'
        }]
        else if(this.solicitacao.vtesolprs.valor === 'C') return [{
          tipsitprs: 'AVAL_PRCEU', titulo: 'Avaliação da Solicitação'
        }]
        else if(this.solicitacao.vtesolprs.valor === 'P') {
          const tipsitprs = this.solicitacao.sitprsatl.tipsitprs
          const situacoes = [{ tipsitprs: 'AVAL_PRPI', titulo: 'Avaliação de 1ª Fase'}]
          if(['AVAL_PRPI2', 'REPR_PRPI2', 'APR_PRPI', 'APROVADO'].includes(tipsitprs)){
            situacoes.push({ tipsitprs: 'AVAL_PRPI2', titulo: 'Avaliação de 2ª Fase'})
          }
          return situacoes
        }
        else return [];
      }
    },

    mounted(){
      const self = this
      self.recarregar()
    },

    methods: {
      ...mapActions({
        carregar: Actions.PROSERV.CARREGAR_SOLICITACAO
      }),
      recarregar(){
        const self = this
        self.carregando = true
        self.carregar({ anosolprs: self.anosolprs, codsolprs: self.codsolprs })
            .catch(erro => self.trataErro(erro))
            .finally(() => self.carregando = false)
      },
      trataErro(erro){
        console.error(erro)
      }
    }
  }
</script>