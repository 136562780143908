<template>
  <section>
    <div class="container-fluid">
      <uspCartao :titulo="titulo">
        <template #corpo>
          <ProServAtendimentoAviso />
          <uspAlerta 
            v-if="!!erro" 
            ref="alertaSalvar" 
            variante="perigo"
            fechavel 
            @fechar="erro = ''"
          >
            {{ erro }}
          </uspAlerta>
          <uspSkeleton v-if="carregando">
            <div>
              <div class="w-50" />
              <div class="w-50" />
            </div>
            <div>
              <div class="w-50" />
              <div class="w-50" />
            </div>
            <div>
              <div class="w-50" />
              <div class="w-50" />
            </div>  
          </uspSkeleton>
          <form
            v-show="!carregando"          
            novalidate
            :class="{ 'was-validated': validado }"
            @submit.prevent="salvar"
          >
            <fieldset>
              <legend>Dados da Unidade</legend>
              <div class="form-row">
                <div class="form-group col-sm">
                  <label 
                    for="codund"
                    class="control-label"
                  >
                    Unidade
                  </label>
                  <select
                    id="codund"
                    v-model="codund"
                    class="form-control"
                    name="codund"
                    required
                    @change="listarDepartamentos"
                  >
                    <option 
                      v-if="unidades.length !== 1"
                      value=""
                    >
                      {{ textoUnidades }}
                    </option>
                    <option 
                      v-for="unidade in unidades"
                      :key="unidade.codund"
                      :value="unidade.codund"
                    >
                      {{ unidade }}
                    </option>
                  </select>
                  <span class="invalid-feedback">{{ erros.codund }}</span>
                </div>
                <div class="form-group col-sm">
                  <label 
                    for="codset"
                    class="control-label"
                  >
                    Departamento/Setor
                    <small>(opcional)</small>
                  </label>
                  <select
                    id="codset"
                    v-model="codset"
                    class="form-control"
                    name="codset"
                  >
                    <option value="">
                      {{ textoSetores }}
                    </option>
                    <option 
                      v-for="dep in departamentos"
                      :key="dep.codund+'-'+dep.codset"
                      :value="dep.codset"
                    >
                      {{ dep }}
                    </option>
                  </select>
                </div>
                <div class="form-group col-sm">
                  <label 
                    for="codpesdct"
                    class="control-label"
                  >
                    Docente Responsável
                  </label>
                  <input
                    id="codpesdct"
                    v-model="codpesdct"
                    class="form-control"
                    type="number"
                    step="1"
                    required
                    @change="obterDocente"
                  >
                  <span class="nome-docente">
                    {{ textoDocente }}
                  </span>
                  <span class="invalid-feedback">{{ erros.codpesdct }}</span>
                </div>
              </div>
            </fieldset>
            <fieldset>
              <legend>
                Dados da Vertente
              </legend>
              <div class="form-row">
                <div class="form-group col-sm">
                  <label 
                    for="vertente"
                    class="control-label"
                  >
                    Vertente
                  </label>
                  <select
                    v-model="stavtesolprs"
                    name="vertente"
                    class="form-control"
                    required
                  >
                    <option value="">
                      - Selecione -
                    </option>
                    <option 
                      v-for="v in vertentes"
                      :key="v.getCodigo()"
                      :value="v.getCodigo()"
                    >
                      {{ v }}
                    </option>
                  </select>
                  <span class="invalid-feedback">{{ erros.vertente }}</span>
                </div>
                <div class="form-group col-sm">
                  <div class="form-group col-sm">
                    <label 
                      for="vertente"
                      class="control-label"
                    >
                      Área de Conhecimento (apenas para pesquisa)
                    </label>
                    <select
                      id="area"
                      v-model="staarecnhpsq"
                      name="area"
                      class="form-control"
                      :required="stavtesolprs === 'PESQUISA'"
                      :disabled="stavtesolprs !== 'PESQUISA'"
                    >
                      <option value="">
                        - Selecione -
                      </option>
                      <option 
                        v-for="a in areas"
                        :key="a.codigo"
                        :value="a.codigo"
                      >
                        {{ a.texto }}
                      </option>
                    </select>
                    <span class="invalid-feedback">{{ erros.area }}</span>
                  </div>
                </div>
              </div>
            </fieldset>
            <fieldset>
              <legend>Dados do Cargo</legend>
              <div class="form-row">
                <div class="form-group col-sm">
                  <label 
                    for="funcao-indicada"
                  >
                    Função Indicada
                  </label>
                  <select 
                    id="funcao-indicada"
                    v-model="codfnccgoprs"
                    name="codfnccgoprs"
                    class="form-control"
                    required
                  >
                    <option value="">
                      - Selecione -
                    </option>
                    <option
                      v-for="funcao in funcoes"
                      :key="'funcao-'+funcao.getCodigo()"
                      :value="funcao.getCodigo()"
                    >
                      {{ funcao.getTexto() }}
                    </option>
                  </select>
                  <span class="invalid-feedback">{{ erros.codfnccgoprs }}</span>
                </div>
                <div class="form-group col-sm">
                  <label 
                    for="area-especializacao"
                  >
                    Área/Especialização
                  </label>
                  <input 
                    id="area-especializacao"
                    v-model="areatcespcgo"
                    name="areatc"
                    class="form-control"
                    :disabled="!temArea"
                    :required="temArea"
                  >
                  <span class="invalid-feedback">{{ erros.areatcespcgo }}</span>
                </div>
              </div>
            </fieldset>
            <div class="acoes d-flex justify-content-end">
              <uspBotao
                type="submit"
                variante="sucesso"
                :carregando="salvando"
                texto-carregando="Salvando"
                texto="Salvar"
                icone="fas fa-save"
              />
              <uspBotao
                variante="perigo"
                icone="fas fa-times"
                texto="Cancelar"
                @clicar="$router.go(-1)"
              />
            </div>
          </form>
        </template>
      </uspCartao>
    </div>
  </section>
</template>

<script>
import ProServAtendimentoAviso from '../componentes/itens/ProServAtendimentoAviso.vue';
import { mapActions, mapGetters } from 'vuex';
import { Actions, Getters } from '../store/types';

export default {
    name: 'ProServForm',

    components: { ProServAtendimentoAviso },

    data() {
        return {
            // Props gerais
            carregando: false,
            carregandoDepartamentos: false,
            carregandoUnidades: false,
            carregandoDocente: false,
            erro: '',
            salvando: false,
            docente: null,
            // Props do modelo
            areatcespcgo: '',
            staarecnhpsq: '',
            codfnccgoprs: '',
            codpesdct: '',
            nompesdct: '',
            codset: '',
            codund: '',
            codsolprs: this.$route.params.codsolprs,
            anosolprs: this.$route.params.anosolprs,
            departamentos: [],
            stavtesolprs: ''
        };
    },
    computed: {
      ...mapGetters({
        areas: Getters.PROSERV.AREAS,
        funcoes: Getters.PROSERV.FUNCOES,
        unidades: Getters.PROSERV.UNIDADES,
        vertentes: Getters.PROSERV.VERTENTES,
        solicitacao: Getters.PROSERV.SOLICITACAO
      }),
      titulo() {
        return this.codsolprs ? `Alterando dados da Solicitação #${this.codsolprs}/${this.anosolprs}` : 'Nova Solicitação'
      },
      temArea(){
        const self = this
        return this.codfnccgoprs ? !!this.funcoes.find(f => f.codfncprs == self.codfnccgoprs && f.staexiareatc) : false
      },
      textoDocente(){
        if(this.carregandoDocente) return 'Carregando docente pelo NUSP...'
        else return this.nompesdct ? this.nompesdct : ''
      },
      textoUnidades(){
        return this.carregandoUnidades ? 'Carregando...' : ' - Selecione -'
      },
      textoSetores(){
        if(!this.codund) return 'Escolha uma unidade...'
        else if(this.carregandoDepartamentos) return 'Carregando...'
        return ' - Selecione -'
      }

    },
    watch:{
      codfnccgoprs(){
        if(!this.temArea){
          this.areatcespcgo = ''
        }
      },
      stavtesolprs(){
        if(this.stavtesolprs != 'PESQUISA'){
          this.staarecnhpsq = ''
        }
      }
    },
    mounted(){
      const self = this,
            promises = []

      self.carregandoForm = true
      if(self.funcoes.length === 0){
        promises.push(
          self.carregarFuncoes()
        )
      }
      if(self.areas.length === 0){
        promises.push(
          self.carregarAreas()
        )
      }
      if(self.vertentes.length === 0){
        promises.push(
          self.carregarVertentes()
        )
      }
      if(self.unidades.length === 0){
        self.carregandoUnidades = true
        promises.push(
          self.carregarUnidades()
              .then((unidades) => {
                if(unidades.length === 1){
                  self.codund = unidades[0].codund
                  self.listarDepartamentos()
                }
              })
              .finally(() => self.carregandoUnidades = false)
        )
      }
      if(self.codsolprs && self.anosolprs){
        promises.push(
          self.carregarDados({ codsolprs: self.codsolprs, anosolprs: self.anosolprs })
              .then(dados => {
                self.codund = dados.undste.codund
                self.codset = dados.setste?.codset ?? 0
                self.codpesdct = dados.dctrsp.codpes
                self.nompesdct = dados.dctrsp.nompes
                self.stavtesolprs = dados.vtesolprs?.chave
                self.staarecnhpsq = dados.arecnhpsq?.chave
                self.codfnccgoprs = dados.cgoprs?.fnccgoprs?.codfncprs
                self.areatcespcgo = dados.cgoprs?.areatcespcgo
                self.listarDepartamentos()
              })
        )
      }

      Promise.all(promises)
             .finally(() => self.carregandoForm = false)
    },
    methods: {
      ...mapActions({
        carregarAreas: Actions.PROSERV.CARREGAR_AREAS,
        carregarDepartamentos: Actions.PROSERV.CARREGAR_DEPARTAMENTOS,
        carregarFuncoes: Actions.PROSERV.CARREGAR_FUNCOES,
        carregarUnidades: Actions.PROSERV.CARREGAR_UNIDADES,
        carregarVertentes: Actions.PROSERV.CARREGAR_VERTENTES,
        carregarDados: Actions.PROSERV.CARREGAR_SOLICITACAO,
        salvarSolicitacao: Actions.PROSERV.SALVAR_SOLICITACAO,
        obterPessoa: Actions.PROSERV.OBTER_PESSOA
      }),
      obterDocente(){
        const self = this
        self.carregandoDocente = true
        self.obterPessoa({ codpes: self.codpesdct })
          .then((docente) => self.nompesdct = docente.nompes)
          .catch(error => {
            self.codpesdct = ''
            self.nompesdct = error.detail
          })
          .finally(() => self.carregandoDocente = false)
      },
      listarDepartamentos(){
        const self = this
        if(self.codund){
          self.carregandoDepartamentos = true
          self.carregarDepartamentos(self.unidades.find(u => u.codund === self.codund))
              .then((deps) => self.departamentos = deps)
              .finally(() => self.carregandoDepartamentos = false)
        }
      },
      salvar(){
        const self = this;
        self.salvando = true;
        self.salvarSolicitacao({
          anosolprs: 2023,
          codsolprs: self.codsolprs,
          codundste: self.codund,
          codsetste: self.codset || null,
          codfnccgoprs: self.codfnccgoprs,
          areatcespcgo: self.areatcespcgo,
          stavtesolprs: self.stavtesolprs,
          codpesdctrsp: self.codpesdct,
          staarecnhpsq: self.staarecnhpsq || null
        })
        .then((dados) => self.$router.push(
              {
                  name: 'Proserv:Detalhes', 
                  params: { 
                  codsolprs: dados.codsolprs, 
                  anosolprs: dados.anosolprs 
                }
              }))
        .catch(error => {
          if (error) {
            self.erro = error.detail || error.message;
          }
        }).finally(() => {
          self.salvando = false;
        })
      },
    },

}
</script>

<style lang="scss" scoped>
  label {
    font-weight: 600;
  }

  span.nome-docente {
    display: flex;
    flex-flow: row nowrap;
    padding: 0.3rem;
    justify-content: center;
  }
</style>