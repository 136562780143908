import { PortalServico } from "@/utils";
import AvaliadorProServ from "./modelos/AvaliadorProServ";

const API_PREFFIX = '/proserv'

export default class ProServAvaliadorServico extends PortalServico {


  listarAvaliadores(anosolprs, codsolprs, tipsitprs){
    const self = this;
    return self.get(`${API_PREFFIX}/solicitacoes/${anosolprs}/${codsolprs}/avaliadores/${tipsitprs}`)
                .then(resultado => resultado.map(item => AvaliadorProServ.fromJSON(item)))
  }
  
  obterAvaliador(anosolprs, codsolprs, tipsitprs, codpesavd){
    const self = this;
    return self.get(`${API_PREFFIX}/solicitacoes/${anosolprs}/${codsolprs}/avaliadores/${tipsitprs}/${codpesavd}`)
                .then(resultado => AvaliadorProServ.fromJSON(resultado))
  }

  salvarAvaliador(anosolprs, codsolprs, tipsitprs, codpesavd){
    const self = this;
    return self.post(`${API_PREFFIX}/solicitacoes/${anosolprs}/${codsolprs}/avaliadores/${tipsitprs}`, { codpesavd })
                .then(item => AvaliadorProServ.fromJSON(item))
  }

  removerAvaliador(anosolprs, codsolprs, tipsitprs, codpesavd){
    const self = this;
    return self.delete(`${API_PREFFIX}/solicitacoes/${anosolprs}/${codsolprs}/avaliadores/${tipsitprs}/${codpesavd}`)
  }

  salvarAvaliacao(anosolprs, codsolprs, tipsitprs, itens){
    const self = this;
    return self.put(`${API_PREFFIX}/solicitacoes/${anosolprs}/${codsolprs}/avaliadores/${tipsitprs}`, { itens })
  }

}