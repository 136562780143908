<template>
  <div class="list-group-item">
    <form
      :id="'form-'+id"
      :class="{ 'was-validated': validado }"
      novalidate
      @submit.prevent="salvar"
    >
      <div 
        class="nusp"
      >
        <label 
          :for="id+'-codpesavd'"
          class="control-label sr-only"
        >
          NUSP Avaliador
        </label>
        <input
          :id="id+'-codpesavd'"
          v-model="codpesavd"
          class="form-control"
          type="number"
          placeholder="Digite o NUSP..."
          step="1"
          required
          @change="obterAvaliador"
        >
        <span 
          :class="{ 'nome': true, 'invalid-feedback': invalido }"
        >
          {{ textoAvaliador }}
        </span>
      </div>
      <div class="acoes">
        <button
          type="submit"
          class="btn btn-outline-primary"
        >
          <em 
            v-if="!salvando"
            class="fas fa-save" 
          />
          <div 
            v-else
            class="spinner-border spinner-border-sm" 
            role="status"
          >
            <span class="sr-only">Salvando...</span>
          </div>
          <span v-if="!salvando">Salvar</span>
        </button>
        <button
          class="btn btn-outline-danger"
          type="reset"
          @click="limpar"
        >
          <em class="fas fa-ban" />
          Cancelar
        </button>
      </div>
      <uspAlerta 
        v-show="alerta.mostrar"
        :variante="alerta.variante"
        :fechavel="true"
        @fechar="alerta.mostrar = false"
      >
        {{ alerta.mensagem }}
      </uspAlerta>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { Actions } from '@/proserv/store/types';
import ProServAvaliadorServico from '@/proserv/dominio/avaliadores/ProServAvaliadorServico';
import UspIdUnico from '@/componentes/plugins/UspIdUnico';

  export default {
    name: 'ProServAvaliadorForm',

    props:{
      situacao: {
        type: Object,
        required: true
      }
    },

    data(){
      return {
        api: ProServAvaliadorServico.build({}),
        alerta: {},
        invalido: false,
        carregando: false,
        salvando: false,
        validado: false,
        codpesavd: '',
        nompesavd: '',
        id: ''
      }
    },

    computed: {
      textoAvaliador(){
        if(this.invalido) return 'NUSP de avaliador é obrigatório'
        else if(this.carregando) return 'Carregando avaliador pelo NUSP...'
        else return this.nompesavd || 'Digite o NUSP para buscar...'
      }
    },

    mounted(){
      const self = this
      if(!self.id){
        self.id = UspIdUnico.gerar()
      }
    },

    methods:{
      ...mapActions({
        obterPessoa: Actions.PROSERV.OBTER_PESSOA
      }),
      obterAvaliador(){
        const self = this
        self.carregando = true
        self.invalido = false
        self.obterPessoa({ 
          codpes: self.codpesavd 
        })
          .then((pessoa) => self.nompesavd = pessoa.nompes)
          .catch(error => {
            self.codpesavd = ''
            self.nompesavd = error.detail
          })
          .finally(() => self.carregando = false)
      },
      limpar(){
        const self = this
        self.salvando = false
        self.validado = false
        self.invalido = false
        self.codpesavd = ''
        self.nompesavd = ''
        self.alerta = {}
      },
      salvar(){
        const self = this
        self.validado = true
        self.alerta = {}
        if(!self.codpesavd) {
          self.invalido = true
          return
        }
        self.salvando = true
        self.api.salvarAvaliador(
          self.$route.params.anosolprs,
          self.$route.params.codsolprs,
          self.situacao.tipsitprs,
          self.codpesavd 
        ).then((avaliador) => {
          self.limpar()
          self.alerta = {
            variante: 'sucesso',
            mensagem: `Avaliador ${avaliador.nompesavd} adicionado com sucesso`,
            mostrar: true
          }
          self.$emit('salvar', avaliador)
        })
        .catch(error => {
          self.alerta = {
            variante: 'perigo',
            mensagem: error.detail,
            mostrar: true
          }
        })
        .finally(() => self.salvando = false)
      }
    }
  }
</script>

<style lang="scss" scoped>
  
  form {

    display: flex;
    flex-flow: row wrap;
    align-items: center;

    * {
      margin: 0 0.6rem;
    }

    div.nusp {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      margin-left: 0;

      .form-control {
        flex: 0 0 10em;
        margin: 0;
      }

      .invalid-feedback {
        display: inline;
        width: auto;
      }

    }

    em.fas,
    div.spinner-border {
      margin: 0;
    }

    

  }
</style>