<template>
  <uspCartao 
    :titulo="situacao.titulo"
    :fechavel="false"
    :classes="{ 'cardHeader': 'header-sticky' }"
  >
    <template #cabecalho>
      {{ situacao.titulo }}
      <uspAlerta
        v-if="mensagem.mostrar"
        style="width: 100%;"
        :variante="mensagem.variante"
        :fechavel="mensagem.fechavel"
        @fechar="mensagem.mostrar = false"
      >
        <em 
          v-if="salvando"
          class="fas fa-2x fa-spinner fa-pulse" 
        />
        {{ mensagem.texto }}
        <div 
          v-if="!salvando && mensagem.variante === 'sucesso'"
          class="progress"
        >
          <div 
            class="progress-bar progress-bar-striped bg-success" 
            role="progressbar"
            :style="'width: '+progresso+'%'"
            :aria-valuenow="progresso" 
            aria-valuemin="0" 
            aria-valuemax="100"
          />
        </div>
      </uspAlerta>
      <div 
        v-if="!carregando && !leitura"
        class="datas"
      >
        <div class="info">
          <span class="resultado">
            <strong>{{ labelNota }}</strong>
            {{ avaliacao.nota ?? 'Não avaliado' }}
          </span>
          <span class="data">
            <strong>Salvo pela última vez em: </strong>
            {{ avaliacao.dtaultalt ?? 'Não avaliado' }}
          </span>
        </div>
        <uspBotao 
          type="button"
          texto="Salvar"
          :carregando="salvando"
          texto-carregando="Salvando..."
          icone="fas fa-save"
          @clicar="salvar"
        />
      </div>
    </template>
    <template #corpo>
      <uspSkeleton v-if="carregando" />
      <div 
        v-if="!carregando && solicitacao && avaliacao"
        class="container-fluid"
      >
        <div 
          v-for="nota in avaliacao.avaliacoes"
          :key="nota.tipcrtprs.codtipcrtprs"
          class="grupo"
        >
          <h4>{{ nota.tipcrtprs.nomtipcrtprs }}</h4>
          <div
            class="list-group"
          >
            <ProServCriterioItem
              v-for="item in nota.filhos"
              :key="'subitem-'+item.tipcrtprs.codtipcrtprs"
              :item="item"
              :situacao="situacao"
              :desabilitado="salvando"
              :leitura="leitura"
              @input="valor => atualizarItem(item, valor)"
            />
          </div>
        </div>
      </div>
    </template>
  </uspCartao>
</template>
<script>
import ProServAvaliadorServico from '@/proserv/dominio/avaliadores/ProServAvaliadorServico';
import ProServCriterioItem from '../itens/ProServCriterioItem.vue';
import { StorageKeys } from '@/portal/constantes';
export default {
    name: 'ProServAvaliacaoCartao',

    components: { ProServCriterioItem },

    props: {
      solicitacao: {
        type: Object,
        required: true
      },
      situacao: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        api: ProServAvaliadorServico.build({}),
        avaliacao: {},
        carregando: false,
        mensagem: {},
        progresso: 0,
        salvando: false,
        validado: false
      }
    },

    computed: {
      codpesavd(){
        return (localStorage.getItem(StorageKeys.AUTH.USUARIO_DISPLAY) || '').split('-')[0]
      },
      labelNota(){
        return this.situacao.tipsitprs === 'AVAL_PRPI' ? 'Situação Atual:' : 'Nota Atual:';
      },
      leitura(){
        return this.situacao.tipsitprs !== this.solicitacao.sitprsatl.tipsitprs
      }
    },

    mounted(){
      const self = this
      self.carregando = true
      self.api.obterAvaliador(
        self.$route.params.anosolprs,
        self.$route.params.codsolprs,
        self.situacao.tipsitprs,
        self.codpesavd
      ).then(objeto => self.avaliacao = objeto)
      .finally(() => self.carregando = false)
    },

    methods: {
      atualizarItem(item, valor){
        item.vlrnotcrt = valor.vlrnotcrt;
        item.jusnotcrt = valor.jusnotcrt;
      },
      mostrarErro(erro){
        this.mensagem = {
          variante: 'perigo',
          mostrar: true,
          fechavel: true,
          texto: erro
        }
      },
      mostrarSucesso(){
        const self = this
        self.progresso = 0
        self.mensagem = {
          texto: 'Formulário salvo com sucesso!',
          variante: 'sucesso',
          mostrar: true
        }
        let interval = setInterval(() => {
          self.progresso += 25
          if(self.progresso > 100){
            self.mensagem = {}
            clearInterval(interval)
          }
        }, 300)
      },
      salvar(){
        const self = this;
        self.mensagem.mostrar = false;
        const itens = self.avaliacao.avaliacoes
                              .flatMap(item => item.filhos)
                              .map(item => { return { vlrnotcrt: item.vlrnotcrt, codtipcrtprs: item.tipcrtprs.codtipcrtprs, jusnotcrt: item.jusnotcrt } })
        if(itens.find(i => i.vlrnotcrt === null || i.vlrnotcrt === undefined)){
          self.mostrarErro('Ao menos um critério está sem nota, verifique!')
          return;
        }
        if(self.situacao.tipsitprs.includes('PRPI') && itens.find(i => !i.jusnotcrt)){
          self.mostrarErro('Justificativa é obrigatória, verifique!')
          return;
        }
        self.salvando = true;
        self.api.salvarAvaliacao(
          self.$route.params.anosolprs,
          self.$route.params.codsolprs,
          self.situacao.tipsitprs,
          itens
        ).then((retorno) => {
          self.mostrarSucesso()
          self.avaliacao.dtaultalt = retorno.dtaultalt
        })
        .catch(erro => self.mostrarErro(erro.detail))
        .finally(() => self.salvando = false)
      }
    }
}
</script>

<style lang="scss" scoped>

  div.grupo {
    border: .1rem solid #CECECE;
    border-radius: 6px;
    padding: .4em;
    margin-bottom: .4rem;

    h4 {
      border-bottom: .1rem solid #CECECE;
      padding-bottom: .2rem;
    }

    .list-group-item {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
    }
  }

  ::v-deep div.header-sticky {
    position: sticky;
    top: 3.5rem;
    z-index: 1020;
    background-color: #FFF;
    border-top-left-radius: 1.1rem;
    border-top-right-radius: 1.1rem;
    border-bottom: 0.0625rem dotted #f5f5f5;
    
    h5 {
      align-items: center;
      display: flex;
      width: 100%;

      div.alert {
        flex: 1 1 20rem;
        margin: 0;
        align-self: center;
      }

      div.datas {
        align-items: center;
        display: flex;
        flex-flow: row;
        font-size: 1rem;
        font-weight: normal;
        margin-left: auto;
        margin-right: 0.8rem;

        div.info {
          display: flex;
          flex-flow: column;
          margin: 0 0.4em;
        }
      }

    }
  }

</style>