<template>
  <form 
    ref="form"
    class="list-group-item form-inline"
    novalidate
  >
    <span class="enunciado">
      {{ item.tipcrtprs.dsctipcrtprs }}
    </span>
    <div 
      v-if="item.tipcrtprs.vlrmaxcrtprs"
      class="conceito-group"
    >
      <span>Critério</span>
      <span v-if="leitura">
        {{ vlrnotcrt }}
      </span>
      <div 
        v-for="n in valores"
        :key="item.tipcrtprs.codtipcrtprs+'-'+n"
        class="custom-control custom-radio custom-control-inline"
        :class="{ 'selecionado': vlrnotcrt === n }"
        @click="atualizarValor(n)"
      >
        <input 
          :id="item.tipcrtprs.codtipcrtprs+'-'+n"
          v-model="vlrnotcrt"
          type="radio" 
          :value="n" 
          :name="item.tipcrtprs.codtipcrtprs"
          class="custom-control-input"
          :disabled="desabilitado"
        >
        <label 
          :for="item.tipcrtprs.codtipcrtprs+'-'+n"
          class="custom-control-label"
        >
          {{ n }}
        </label>
      </div>
    </div>
    <div 
      v-else
      class="conceito-group"
    >
      <span>Situação</span>
      <div v-if="item.leitura">
        <span>
          {{ notaTexto }}
        </span>
      </div>
      <div 
        v-if="!item.leitura"
        class="form-check form-check-inline"
      >
        <input
          :id="item.tipcrtprs.codtipcrtprs+'-sim'"
          v-model="vlrnotcrt"
          type="radio" 
          value="1" 
          :name="item.tipcrtprs.codtipcrtprs"
          class="form-check-input"
          :disabled="desabilitado"
        >
        <label 
          :for="item.tipcrtprs.codtipcrtprs+'-sim'"
          class="form-check-label"
        >
          Sim
        </label>
      </div>
      <div 
        v-if="!item.leitura"
        class="form-check form-check-inline"
      >
        <input 
          :id="item.tipcrtprs.codtipcrtprs+'-nao'"
          v-model="vlrnotcrt"
          type="radio" 
          value="0" 
          :name="item.tipcrtprs.codtipcrtprs"
          class="form-check-input"
          :disabled="desabilitado"
        >
        <label 
          :for="item.tipcrtprs.codtipcrtprs+'-nao'"
          class="form-check-label"
        >
          Não
        </label>
      </div>
    </div>
    <div 
      v-if="item.tipcrtprs.staexijus && !item.leitura"
      class="form-row justificativa form-group"
    >
      <label 
        :for="item.tipcrtprs.codtipcrtprs+'-jusnotcrt'"
      >
        Justificativa
      </label>
      <textarea 
        :id="item.tipcrtprs.codtipcrtprs+'-jusnotcrt'"
        v-model="jusnotcrt"
        name="jusnotcrt"
        class="form-control"
        rows="8"
        :disabled="desabilitado"
      />
      <span class="invalid-feedback">Justificativa é obrigatória</span>
      <div class="contador">
        Usados <span class="count">{{ caracteres }}</span> de <span class="count">1000</span> caracteres.
      </div>
    </div>
    <div 
      v-if="item.tipcrtprs.staexijus && item.leitura"
      class="form-row justificativa form-group"
    >
      <span style="font-weight: 900;">Justificativa</span>
      <div>{{ jusnotcrt || 'Não Avaliado' }}</div>
    </div>
  </form>
</template>

<script>
import ProServAvaliadorServico from '@/proserv/dominio/avaliadores/ProServAvaliadorServico';
import AvaliacaoProServ from '@/proserv/dominio/avaliadores/modelos/AvaliacaoProServ.js';

  export default {
    name: 'ProServCriterioItem',

    props:{
      item: {
        type: AvaliacaoProServ,
        required: true
      },
      leitura: {
        type: Boolean,
        default: false
      },
      desabilitado: {
        type: Boolean,
        default: false
      },
      situacao: {
        type: Object,
        required: true
      },
    },

    data(){
      return {
        api: ProServAvaliadorServico.build({}),
        dataRetornada: '',
        erro: '',
        mensagem: '',
        justificativa: '',
        salvando: false,
        variante: '',
        vlrnotcrt: this.item.vlrnotcrt,
        jusnotcrt: this.item.jusnotcrt || ''
      }
    },

    computed:{
      data:{
        get(){
          return this.dataRetornada || this.item.dtaultalt || this.item.dtacad
        },
        set(d){
          this.dataRetornada = d
        }
      },
      valores(){
        return this.leitura ? [] : Array((this.item.tipcrtprs.vlrmaxcrtprs / 0.5) + 1).fill().map((_e,i) => i*0.5)
      },
      notaTexto(){
        switch (this.item.vlrnotcrt) {
          case 0: return 'Não'
          case 1: return 'Sim'
          default: return 'Não avaliado';
        }
      },
      caracteres(){
        return this.jusnotcrt.replace(/\s/g, '').length
      }
    },

    watch:{
      vlrnotcrt(){
        this.$emit('input', { vlrnotcrt: this.vlrnotcrt, jusnotcrt: this.jusnotcrt });
      },
      jusnotcrt(valor){
        if(valor.replace(/\s/g, '').length > 1000){
          let limite = 1000;
          for(let i = 0; i < valor.length; i++){
            if(!/\s/g.test(valor.charAt(i))){
              limite --;
            }
            if(limite === 0){
              this.jusnotcrt = valor.substring(0, i+1);
            }
          }
        }
        this.$emit('input', { vlrnotcrt: this.vlrnotcrt, jusnotcrt: this.jusnotcrt });
      },
    },

    methods: {
      atualizarValor(n){
        if(!this.desabilitado){
          this.vlrnotcrt = n;
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
div.conceito-group {
  display: flex;
  margin: 0 .8rem;
  flex: 1;
  flex-flow: row wrap;
  align-items: center;

  span:not(.invalid-feedback) {
    font-size: 1rem;
    font-weight: bold;
    display: inline;
    margin-right: .5rem;
  }

  div.custom-control.custom-radio {
    border: 1px solid #CECECE;
    border-radius: 25%;
    padding: 0.3rem 0.5rem 0.3rem 2rem;
    background: #f8f9fa;
  }

  div.custom-control.custom-radio.selecionado {
    font-weight: 900;
    background: #c2ceda;
    border-width: 2px;
  }

}

div.form-row.justificativa {
    display: flex;
    flex-flow: column;
    align-items: stretch;
    width: 100%;
    margin: .8rem 0;
    label {
      display: block;
      font-weight: 900;
    }
  }

span.enunciado {
  color: #1094ab;
  font-weight: 900;
}
</style>