<!-- eslint-disable vue/no-v-html -->
<template>
  <uspCartao 
    titulo="Detalhes Solicitação"
  >
    <template #corpo>
      <uspSkeleton v-if="carregando" />
      <div
        v-else 
        class="container-fluid"
      >
        <div class="campo-texto">
          <h3>Justificativa</h3>
          <div 
            class="texto"
            v-html="solicitacao.detalhes?.jussol"
          />
        </div>
        <div class="campo-texto">
          <h3>Estrutura</h3>
          <div 
            class="texto"
            v-html="solicitacao.detalhes?.etrsol"
          />
        </div>
        <div class="arquivos">
          <h3>Arquivos</h3>
          <uspSkeleton v-if="carregandoArquivos">
            <div class="w-100" />
          </uspSkeleton>
          <div 
            v-else
            class="list-group"
          >
            <a
              v-for="arquivo in arquivos"
              :key="'arquivo-'+arquivo.numseqarqprs"
              class="list-group-item arquivo"
              :href="`/proserv/solicitacoes/${$route.params.anosolprs}/${$route.params.codsolprs}/arquivos/${arquivo.numseqarqprs}`"
              @click.prevent="download(arquivo)"
            >
              <em :class="arquivo.getIcone()" />
              <span class="nome-arquivo">
                {{ arquivo }}
              </span>
              <small 
                v-if="baixando"
                class="loader"
              >
                <div 
                  class="spinner-border spinner-border-sm text-primary" 
                  role="status" 
                />
                Obtendo arquivo...
              </small>
            </a>
          </div>
        </div>
        <div 
          v-if="solicitacao.detalhes?.pcenaoaprsol"
          class="campo-texto"
        >
          <h3>Parecer de Não Contemplado</h3>
          <div 
            class="texto"
            v-html="solicitacao.detalhes?.pcenaoaprsol"
          />
        </div>
        <div 
          v-if="solicitacao.detalhes?.jusrcs"
          class="campo-texto"
        >
          <h3>Justificativa de Recurso</h3>
          <div 
            class="texto"
            v-html="solicitacao.detalhes?.jusrcs"
          />
        </div>
        <div 
          v-if="solicitacao.detalhes?.pcercs"
          class="campo-texto"
        >
          <h3>Recurso</h3>
          <p>
            <span class="label">Contemplado: </span>
            {{ solicitacao.sitprsatl.tipsitprs === 'REC_APROVA' ? 'SIM' : 'NÃO' }}
          </p>
          <p v-if="solicitacao.solori">
            <span class="label">Substituiu: </span>
            <router-link
              :to="{
                name: 'Proserv:Detalhes',
                params: { 
                  codsolprs: solicitacao.solori.codsolprs,
                  anosolprs: solicitacao.solori.anosolprs
                },
              }"
              target="_blank"
            >
              Solicitação #{{ solicitacao.solori.codsolprs }}/{{ solicitacao.solori.anosolprs }}
            </router-link>
          </p>
          <span class="label">Parecer:</span>
          <div 
            class="texto"
            v-html="solicitacao.detalhes?.pcercs"
          />
        </div>
      </div>
    </template>
  </uspCartao>
</template>
<script>
import { Actions, Getters } from '@/proserv/store/types';
import { mapActions, mapGetters } from 'vuex';


export default {
    name: 'ProServDetalhesCartao',

    props: {
      carregando: {
        type: Boolean,
        default: true
      },
      solicitacao: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        baixando: false,
        carregandoArquivos: false
      }
    },

    computed: {
      ...mapGetters({
        arquivos: Getters.PROSERV.ARQUIVOS
      })
    },

    mounted(){
      const self = this
      self.carregandoArquivos = true
      self.carregarArquivos({
        anosolprs: self.$route.params.anosolprs,
        codsolprs: self.$route.params.codsolprs
      }).finally(() => self.carregandoArquivos = false)
    },

    methods: {
      ...mapActions({
        carregarArquivos: Actions.PROSERV.CARREGAR_ARQUIVOS,
        obterArquivo: Actions.PROSERV.OBTER_ARQUIVO
      }),
      download(arquivo){
        const self = this
        if(self.baixando) return false;
        self.baixando = true
        self.obterArquivo({
          numseqarqprs: arquivo.numseqarqprs,
          anosolprs: self.$route.params.anosolprs,
          codsolprs: self.$route.params.codsolprs
        })
        .then(response => {
          const blob = new Blob([response])
          const url = URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          link.target = '_blank'
          link.download = arquivo.nomarqdla
          link.click()
          URL.revokeObjectURL(link.href)
        }).catch(console.error)
        .finally(() => self.baixando = false)
      }
    }

}
</script>

<style lang="scss" scoped>

  a.list-group-item.arquivo {
    span.nome-arquivo {
      margin: 0 .7rem;
    }
  }

  div.campo-texto, div.arquivos {
    border: .1rem solid #CECECE;
    border-radius: 6px;
    padding: .4em;
    margin-bottom: .4rem;

    div.texto {
      max-height: 500px;
      overflow-y: auto;
    }

    span.label {
      color: #1094ab;
      font-weight: 900;
    }

    h3 {
      border-bottom: .1rem solid #CECECE;
      padding-bottom: .2rem;
    }
  }

</style>