<template>
  <uspCartao 
    :fechavel="false"
    :titulo="titulo"
  >
    <template #corpo>
      <ProServAtendimentoAviso />
      <uspSkeleton v-if="carregando" />
      <div
        v-if="!carregando && solicitacao"
        class="minicard-container"
      >
        <div class="minicard">
          <div class="card-header">
            Dados Base
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col">
                <span class="label">
                  Unidade
                </span>
                <span class="texto">
                  {{ solicitacao.undste }}
                </span>
              </div>
              <div class="col">
                <span class="label">
                  Departamento
                </span>
                <span class="texto">
                  {{ solicitacao.setste?.toString() || 'não informado' }}
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <span class="label">
                  Docente Responsável
                </span>
                <span class="texto">
                  {{ solicitacao.dctrsp }}
                </span>
              </div>
              <div class="col">
                <span class="label">
                  Vertente
                </span>
                <span class="texto">
                  {{ solicitacao.vtesolprs?.texto }}
                  <small v-if="solicitacao.arecnhpsq">
                    ({{ solicitacao.arecnhpsq?.texto }})
                  </small>
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <span class="label">
                  Função Indicada
                </span>
                <span class="texto">
                  {{ solicitacao.cgoprs?.fnccgoprs?.nomfncprs }}
                </span>
              </div>
              <div 
                v-if="solicitacao.cgoprs?.areatcespcgo"
                class="col"
              >
                <span class="label">
                  Área/Especialidade
                </span>
                <span class="texto">
                  {{ solicitacao.cgoprs?.areatcespcgo ?? 'não há' }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="minicard">
          <div class="card-header">
            Dados de Acompanhamento
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col">
                <span class="label">
                  Criado Em
                </span>
                <span class="texto">
                  {{ solicitacao.dtacad }}
                </span>
              </div>
              <div class="col">
                <span class="label">
                  Situação
                </span>
                <span class="texto">
                  {{ solicitacao.sitprsatl }}
                </span>
              </div>
              <div class="col">
                <span class="label">
                  Alterado pela última vez
                </span>
                <span class="texto">
                  {{ solicitacao.dtaultalt }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="minicard">
          <div class="card-header">
            Ações
          </div>
          <div class="card-body">
            <uspAlerta
              v-show="erro"
              variante="perigo"
              fechavel
              @fechar="erro = null"
            >
              {{ (erro || {}).detail }}
            </uspAlerta>
            <div
              v-if="solicitacao" 
              class="acoes"
            >
              <router-link
                v-if="solicitacao.permissoes?.alterar"
                :to="{
                  name: 'Proserv:Alterar',
                  params: { codsolprs: solicitacao.codsolprs, anosolprs: solicitacao.anosolprs },
                }"
                tag="a"
                class="btn btn-primary"
              >
                <em class="fa fa-edit" />
                Alterar Dados
              </router-link>
              <uspBotao
                v-if="solicitacao.permissoes?.remover"
                texto="Remover"
                variante="perigo"
                icone="fas fa-times"
                :carregar="removendo"
                texto-carregando="Removendo..."
                @clicar="remover"
              />
              <uspBotao
                v-if="solicitacao.permissoes?.envpro"
                :texto="`Enviar para a ${proReitoria}`"
                variante="secundario"
                icone="fas fa-share-square"
                @clicar="confirmarEnviar"
              />
            </div>
          </div>
        </div>
        <uspModal 
          ref="confirmar"
          :titulo="tituloConfirmar"
        >
          <template #body>
            <p>
              {{ textoConfirmar }}
            </p>
            <p v-show="atencaoConfirmar">
              <strong>Atenção:</strong> essa ação não pode ser desfeita e após o envio não há mais possibilidade de alterar os dados da solicitação.
            </p>
          </template>
          <template #footer>
            <button
              class="btn btn-danger"
              @auxclick="fecharModal"
            >
              <span class="fas fa-times" />
              Cancelar
            </button>
            <uspBotao
              variante="sucesso"
              texto="Confirmar"
              icone="fas fa-check"
              :carregando="confirmando"
              texto-carregando="Confirmando..."
              @clicar="acaoConfirmar.call(this)"
            />
          </template>
        </uspModal>
      </div>
    </template>
  </uspCartao>
</template>
<script>
import SolicitacaoProServ from '@/proserv/dominio/modelos/SolicitacaoProServ';
import ProServAtendimentoAviso from '../itens/ProServAtendimentoAviso.vue';
import { Actions } from '@/proserv/store/types';
import { mapActions } from 'vuex';

export default {
    name: 'ProServDadosCartao',

    components: { ProServAtendimentoAviso },

    props: {
      carregando: {
        type: Boolean,
        default: true
      },
      solicitacao: {
        type: SolicitacaoProServ,
        required: true
      }
    },

    data() {
      return {
        acaoConfirmar: null,
        confirmando: false,
        erro: null,
        removendo: false,
        atencaoConfirmar: false,
        tituloConfirmar: '',
        textoConfirmar: ''
      }
    },

    computed: {
      titulo(){
        return this.solicitacao.codsolprs ? `Solicitação #${this.solicitacao.codsolprs}/${this.solicitacao.anosolprs}` : 'Carregando...'
      },
      proReitoria(){
        switch (this.solicitacao.vtesolprs?.chave) {
          case 'PESQUISA': return 'PRPI'
          case 'ENSINO': return 'PRG'
          case 'CULTURA_E_EXTENSAO': return 'PRCEU'
          default: return '';
        }
      }
    },
    methods:{
      ...mapActions({
          enviarSolicitacao: Actions.PROSERV.ENVIAR_SOLICITACAO,
          removerSolicitacao: Actions.PROSERV.REMOVER_SOLICITACAO
      }),
      confirmarEnviar(){
        const self = this;
        self.atencaoConfirmar = true
        self.textoConfirmar = `Deseja mesmo enviar sua solicitação para a ${self.proReitoria} analisar?`
        self.tituloConfirmar = `Confirmar envio para a ${self.proReitoria}?`
        self.acaoConfirmar = self.enviar
        self.$refs.confirmar.openModal();
      },
      enviar(){
        const self = this
        self.$refs.confirmar.closeModal();
        self.confirmando = true
        self.enviarSolicitacao({
          codsolprs: self.solicitacao.codsolprs,
          anosolprs: self.solicitacao.anosolprs,
          tipsitprs: 'AVAL_'+self.proReitoria
        }).then(() => self.$emit('enviar'))
          .catch(erro => self.erro = erro)
          .finally(() => self.confirmando = false)
      },
      fecharModal(){
        const self = this;
        self.atencaoConfirmar = false
        self.acaoConfirmar = null
        self.textoConfirmar = ''
        self.tituloConfirmar = ''
        self.$refs.confirmar.closeModal();
      },
      remover(){
        const self = this
        if(confirm(`Deseja remover a solicitação ${self.solicitacao.codsolprs}/${self.solicitacao.anosolprs}?`)){
          self.removendo = true
          self.removerSolicitacao(this.solicitacao)
            .then(() => self.$router.push('/proserv/'))
        }
      }
    }

}
</script>

<style lang="scss" scoped>

  .minicard-container {
    box-shadow: 0 5px 20px 0 rgba(69, 67, 96, 0.05);
    .minicard:first-of-type {
      border-top-left-radius: 1.1rem;
      border-top-right-radius: 1.1rem;
      .card-header {
        border-top-left-radius: 1.1rem;
        border-top-right-radius: 1.1rem;
      }
    }

    .minicard:last-of-type {
      border-bottom-left-radius: 1.1rem;
      border-bottom-right-radius: 1.1rem;
    }

    .minicard {
      box-shadow: none;
      border: 0.1em solid rgba(0, 0, 0, 0.125) !important;
      margin: 0;
      margin-bottom: -0.1em;
      border-radius: 0;
      .card-header {
        background-color:rgba(0, 0, 0, 0.125);
        color: #1094ab;
        font-size: 1.2rem;
        padding: 0.8rem;
        justify-content: center;
        *:first-child {
          border-left: 0;
          padding-left: initial;
        }
      }
      .card-body {
        border-bottom: 0.0625rem dotted #f5f5f5;
        border-top: 0.0625rem dotted #f5f5f5;
        div.row {
          margin: 0.3rem 0;
          div {
            display: flex;
            flex-flow: column nowrap;

          }
        }

        div.acoes {
          text-align: center;
        }
      }
    }
  }
  span.label {
    color: #1094ab;
    font-weight: 900;
  }

</style>