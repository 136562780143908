<!-- eslint-disable vue/no-v-html -->
<template>
  <uspCartao 
    :titulo="titulo"
  >
    <template #corpo>
      <uspSkeleton v-if="carregando" />
      <div class="editor-container">
        <uspAlerta
          :variante="varianteAlerta"
        >
          <em 
            v-if="salvando"
            class="fas fa-2x fa-spinner fa-pulse" 
          />
          {{ varianteTexto }}
          <div 
            v-if="mensagem"
            class="progress"
          >
            <div 
              class="progress-bar progress-bar-striped bg-success" 
              role="progressbar"
              :style="'width: '+progresso+'%'"
              :aria-valuenow="progresso" 
              aria-valuemin="0" 
              aria-valuemax="100"
            />
          </div>
        </uspAlerta>
        <div 
          class="form-row form-group"
        >
          <textarea 
            id="proserv-recurso-"
            v-model="jusrcs"
            name="texto"
            class="form-control"
            rows="10"
            aria-label="Texto de Recurso"
          />
        </div>
      </div>
    </template>
    <template #rodape>
      <div class="acoes">
        <p class="contador">
          Contagem de Caracteres: 
          <span class="atual">
            {{ caracteres }}
          </span>
          de
          <span class="total">
            {{ total }}
          </span>
        </p>
        <uspBotao
          icone="fas fa-save"
          texto="Salvar e Enviar"
          :carregando="salvando"
          texto-carregando="Enviando..."
          @clicar="salvar"
        />
      </div>
    </template>
  </uspCartao>
</template>
<script>
import ProServServico from '@/proserv/dominio/modelos/ProServServico';


export default {
    name: 'ProServRecursoCartao',

    props: {
      carregando: {
        type: Boolean,
        default: true
      },
      solicitacao: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        api: ProServServico.build({}),
        erro: '',
        salvando: false,
        mensagem: '',
        jusrcs: this.solicitacao.detalhes.jusrcs,
        total: 500,
        contador: 0,
        progresso: 0
      }
    },

    computed: {
      caracteres(){
        return this.jusrcs?.replace(/\s/g, '').length ?? 0;
      },
      titulo(){
        return this.solicitacao ? 'Envio de Recurso' : 'Carregando...'
      },
      varianteAlerta(){
        if (this.erro) return 'perigo'
        else if(this.mensagem) return 'sucesso'
        else if(this.salvando) return 'primario'
        else return 'secundario'
      },
      varianteTexto(){
        if(this.erro) return this.erro
        else if(this.mensagem) return this.mensagem
        else if(this.salvando) return 'Salvando conteúdo...'
        return 'O recurso pode ser enviado para análise até o dia 28/09/2023'
      }
    },

    watch:{
      jusrcs(){
        if(this.jusrcs){
          this.erro = ''
        }
      }
    },

    methods: {
      mostrarSucesso(){
        const self = this
        self.progresso = 0
        self.mensagem = 'Texto salvo com sucesso!'
        let interval = setInterval(() => {
          self.progresso += 25
          if(self.progresso > 100){
            self.mensagem = ''
            clearInterval(interval)
          }
        }, 300)
      },
      salvar(){
        const self = this
        self.erro = '';
        if(!this.jusrcs){
          self.erro = `O campo de justificativa é obrigatório!`
          return
        }
        if(this.caracteres > this.total){
          self.erro = `O campo está com mais do que os ${this.total} caracteres permitidos!`
          return
        }
        self.salvando = true
        self.api.alterarDetalhe(
          'jusrcs', 
          self.jusrcs, 
          self.solicitacao.anosolprs, 
          self.solicitacao.codsolprs
        ).then(() => self.$emit('sucesso'))
         .catch(erro => self.erro = erro)
         .finally(() => self.salvando = false)
      }
    }

}
</script>

<style lang="scss" scoped>

  div.acoes {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;

    p.contador {
      font-weight: 500;
      margin: 0 .75rem;
      span.atual, span.total {
        color: #1094ab;
      }
    }
  }

</style>