import { render, staticRenderFns } from "./ProServForm.vue?vue&type=template&id=40cf8888&scoped=true&"
import script from "./ProServForm.vue?vue&type=script&lang=js&"
export * from "./ProServForm.vue?vue&type=script&lang=js&"
import style0 from "./ProServForm.vue?vue&type=style&index=0&id=40cf8888&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40cf8888",
  null
  
)

export default component.exports