import SituacaoProServ from "../../modelos/SituacaoProServ"
import UnidadeProServ from "../../modelos/UnidadeProServ"
import Aprovador from "./Aprovador"


export default class Aprovacao {

  constructor(codsolprs, anosolprs, media, undste, avaliadores, leitura, tipsitprsatl, desvio){
    this.codsolprs = codsolprs
    this.anosolprs = anosolprs
    this.media = media
    this.desvio = desvio
    this.undste = undste
    this.leitura = leitura
    this.tipsitprsatl = tipsitprsatl
    this.avaliadores = avaliadores
  }

  static fromJSON(json){
    const instancia =  new Aprovacao(
      json.codsolprs,
      json.anosolprs,
      json.media,
      UnidadeProServ.fromJSON(json.undste),
      json.avaliadores.map(item => Aprovador.fromJSON(item)),
      json.leitura,
      SituacaoProServ.fromJSON(json.tipsitprsatl),
      json.desvio
    );
    instancia.justificado = json.justificado
    return instancia
  }


}