<!-- eslint-disable vue/no-v-html -->
<template>
  <uspCartao 
    :titulo="titulo"
  >
    <template #corpo>
      <uspSkeleton v-if="carregando" />
      <div
        v-else
        class="avaliadores-container"
      >
        <div 
          v-if="!carregando && avaliadores.length === 0"
          class="d-flex justify-content-center align-items-center flex-column aviso-vazio"
        >
          <p class="font-weight-bold text-center mt-5">
            {{ edicao ? 'Não há avaliadores salvos!' : 'Solicitação não está na situação correpondente!' }}
          </p>
        </div>
        <uspAlerta 
          v-if="alerta"
          :variante="alerta.variante"
          fechavel
        >
          {{ alerta.mensagem }}
        </uspAlerta>
        <div class="list-group">
          <div 
            v-for="avaliador in avaliadores"
            :key="avaliador.codpesavd"
            class="list-group-item avaliador"
          >
            <div class="descricao">
              <span class="nusp">{{ avaliador.codpesavd }}</span>
              <span class="nome">{{ avaliador.nompesavd }}</span>
              <span class="nota">
                {{ formatarNota(avaliador) }}
              </span>
              <div class="acoes">
                <button
                  v-if="avaliador.nota == null && !leitura"
                  class="btn btn-outline-danger"
                  @click="remover(avaliador)"
                >
                  <em 
                    v-if="!estaRemovendo(avaliador)"
                    class="fas fa-trash" 
                  />
                  <div 
                    v-else
                    class="spinner-border spinner-border-sm" 
                    role="status"
                  >
                    <span class="sr-only">Removendo...</span>
                  </div>
                  Remover
                </button>
              </div>
              <div 
                v-if="situacao.tipsitprs !== 'AVAL_PRPI' && situacao.tipsitprs !== 'AVAL_PRPI2'"
                class="avaliacao list-group list-group-flush"
              >
                <div 
                  v-for="nota in avaliador.avaliacoes"
                  :key="nota.tipcrtprs.codtipcrtprs"
                  class="grupo list-group-item"
                >
                  <h4 class="h6">
                    {{ nota.tipcrtprs.nomtipcrtprs }}
                  </h4>
                  <div
                    v-for="item in nota.filhos"
                    :key="'subitem-'+item.tipcrtprs.codtipcrtprs"
                  >
                    <span class="enunciado">
                      {{ item.tipcrtprs.dsctipcrtprs }}
                    </span>
                    <span class="conceito">
                      Conceito: 
                      <span>{{ item.vlrnotcrt }}</span>
                    </span>
                  </div>
                </div>
              </div>
              <div 
                v-else
                class="justificativa"
              >
                <span class="label">Justificativa:</span>
                <div v-html="avaliador.avaliacoes[0]?.jusnotcrt" />
              </div>
            </div>
          </div>
          <ProServAvaliadorForm 
            v-if="!leitura && edicao"
            :situacao="situacao"
            @salvar="sucesso"
          />
        </div>
      </div>
    </template>
  </uspCartao>
</template>
<script>

import ProServAvaliadorForm from '../forms/ProServAvaliadorForm.vue';
import ProServAvaliadorServico from '@/proserv/dominio/avaliadores/ProServAvaliadorServico.js';

export default {
    name: 'ProServAvaliadoresCartao',

    components: { ProServAvaliadorForm },

    props: {
      solicitacao: {
        type: Object,
        required: true
      },
      situacao: {
        type: Object,
        required: true
      },
      leitura: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        api: ProServAvaliadorServico.build({}),
        alerta: null,
        avaliadores: [],
        carregando: false,
        removendo: false
      }
    },

    computed: {
      edicao(){
        return this.situacao.tipsitprs === this.solicitacao.sitprsatl.tipsitprs;
      },
      titulo(){
        if(this.carregando) return 'Carregando...'
        return this.situacao.titulo
      }
    },

    mounted(){
      const self = this
      self.carregando = true
      self.api.listarAvaliadores(
        self.$route.params.anosolprs,
        self.$route.params.codsolprs,
        self.situacao.tipsitprs
      ).then(lista => self.avaliadores = lista)
      .finally(() => self.carregando = false)
    },

    methods: {
      estaRemovendo(avaliador){
        return this.removendo === avaliador
      },
      sucesso(avaliador){
        this.avaliadores.push(avaliador);
      },
      formatarNota(avaliador){
        let label, nota;
        if(this.solicitacao.vtesolprs.valor === 'P' && this.situacao.tipsitprs === 'AVAL_PRPI'){
          label = 'Aprovado';
          switch(avaliador.nota) {
            case 1: nota = 'Sim'; break;
            case 0: nota = 'Não'; break;
            default: nota = 'Não Avaliado';
          }
        } else {
          label = 'Nota'
          nota = (avaliador.nota || 'Não Avaliado')
        }
        return `${label}: ${nota}`
      },
      remover(avaliador){
        const self = this
        self.removendo = avaliador
        self.api.removerAvaliador(
          self.$route.params.anosolprs,
          self.$route.params.codsolprs,
          self.situacao.tipsitprs,
          avaliador.codpesavd,
        ).then(() => {
          this.alerta = {
            variante: 'sucesso',
            mensagem: `Avaliador ${avaliador.toString()} removido com sucesso!`
          }
          self.avaliadores = self.avaliadores.filter(ava => ava.codpesavd !== avaliador.codpesavd)
        })
        .catch(erro => {
          this.alerta = {
            variante: 'perigo',
            mensagem: erro.detail
          }
        })
        .finally(() => self.removendo = false)
      }
    }

}
</script>

<style lang="scss" scoped>

  div.descricao {
    align-items: center;
    display: flex;
    flex-flow: row wrap;

    > * {
      margin: 0 0.6rem;
    }

    span.nusp {
      font-weight: 900;
      flex: 0 0 10em;
      text-align: center;
      margin: 0;
    }

    span.nome {
      flex: 0 0 20em;
    }

    div.acoes {
      em.fas, div.spinner-border {
        margin: 0
      }
    }

    div.justificativa {
      width: 100%;
      position: relative;
      margin: 0.75rem 0;
      border: 1px solid #ddd;
      border-radius: 8px;
      padding: 10px;

      span.label {
        position: absolute;
        top: 0;
        font-weight: 900;
        line-height: 1;
        margin: -9px 0 0; /* half of font-size */
        background: #fff;
        color: #1094ab;
        padding: 0 3px;
      }
    }

    div.avaliacao {
      width: 100%;
      margin: 0;

      h4 {
        margin: 0;
      }
      span.enunciado {
        color: #1094ab;
        font-weight: 900;
      }
      span.conceito {
        font-weight: 700;
        margin-left: 5px;
        span {
          font-weight: normal;
          margin-left: 3px;
        }
      }
    }
  }

</style>