<template>
  <section>
    <div class="container-fluid">
      <uspCartao titulo="Buscar Solicitações">
        <template #corpo>
          <ProServAtendimentoAviso />
          <div class="container-fluid">
            <div class="row filtros">
              <form @submit.prevent="buscar()">
                <div class="row">
                  <div class="form-group col-lg-3">
                    <label for="busca-unidade">Unidade</label>
                    <select 
                      id="busca-unidade" 
                      v-model="codund"
                      name="codund" 
                      class="form-control"
                      :disabled="carregandoUnidades"
                      @change="listarDepartamentos"
                    >
                      <option 
                        v-if="unidades.length !== 1"
                        value=""
                      >
                        {{ textoUnidades }}
                      </option>
                      <option 
                        v-for="unidade in unidades"
                        :key="unidade.codund"
                        :value="unidade.codund"
                      >
                        {{ unidade }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group col-lg-3">
                    <label for="busca-setor">Departamento/Setor</label>
                    <select 
                      id="busca-setor" 
                      v-model="codset"
                      name="codset" 
                      class="form-control"
                      :disabled="carregandoDepartamentos"
                    >
                      <option value="">
                        {{ textoSetores }}
                      </option>
                      <option 
                        v-for="dep in departamentos"
                        :key="dep.codund+'-'+dep.codset"
                        :value="dep.codset"
                      >
                        {{ dep }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group col-lg-3">
                    <label for="busca-vertente">Vertente</label>
                    <select 
                      id="busca-vertente" 
                      v-model="vertente"
                      name="vertente" 
                      class="form-control"
                    >
                      <option value="">
                        Todas
                      </option>
                      <option 
                        v-for="ver in vertentes"
                        :key="ver.getCodigo()"
                        :value="ver.valor"
                      >
                        {{ ver }}
                      </option>
                    </select>
                  </div>
                  <div class="buscar-acoes col-lg-2">
                    <router-link
                      :to=" { name: 'Proserv:Novo' }"
                      class="btn btn-success"
                    >
                      <em class="fa fa-plus" />
                      Incluir
                    </router-link>
                    <uspBotao
                      type="submit"
                      texto="Buscar"
                      icone="fas fa-search"
                      :disabled="carregando"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </template>
      </uspCartao>
      <PortalCardPaginado 
        titulo="Solicitações ProServ"
        icone="fas fa-tasks"
        :lista="listaFiltrada"
        :por-pagina="porPagina"
        class="usp-tarefas portal-tarefas-cartao"
      >
        <template #filtros>
          <form v-if="!carregando">
            <div class="form-group">
              <label
                for="filtro-lista"
                class="sr-only"
              >
                {{ filtroLabel }}
              </label>
              <input
                id="filtro-lista"
                v-model="filtro"
                name="filtro"
                type="search"
                class="form-control form-control-sm"
                :placeholder="filtroLabel"
              >
              <small 
                id="filtro-lista-help"
                class="form-text text-muted"
              >
                {{ filtroTextoHelp }}
              </small>
            </div>
          </form>
        </template>
        <template #corpo>
          <div 
            v-if="!carregando && listaFiltrada.length === 0"
            class="d-flex justify-content-center align-items-center flex-column aviso-vazio"
          >
            <img 
              src="/imgs/usp-tarefas.svg"
              alt="Ilustração de uma pilha de tarefas concluídas"
            >
            <p class="font-weight-bold text-center mt-5">
              &nbsp; Não há solicitações.
            </p>
          </div>
          <uspSkeleton v-if="carregando">
            <div>
              <div class="w-50" />
              <div class="w-50" />
            </div>
            <div>
              <div class="w-50" />
              <div class="w-50" />
            </div>
            <div>
              <div class="w-50" />
              <div class="w-50" />
            </div>
            <div>
              <div class="w-50" />
              <div class="w-50" />
            </div>
            <div>
              <div class="w-50" />
              <div class="w-50" />
            </div>
          </uspSkeleton>
        </template>
        <template #default="conteudo">
          <div 
            v-if="!carregando"
            class="solicitacao-item"
          >
            <div class="container-fluid usp-resultados destaque">
              <div class="row mx-1">
                <div class="titulo">
                  <h2 class="h5">
                    Solicitação #{{ conteudo.item.codsolprs }}/{{ conteudo.item.anosolprs }}
                    {{ conteudo.item.permissoes.avaliar ? '(Avaliador)' : '' }}
                  </h2>
                </div>
              </div>
              <div class="descricao">
                <div class="row align-items-center">
                  <div class="col">
                    <span class="desc-item row mx-1"><span class="label mr-1">Unidade: </span>{{ conteudo.item.undste || 'Não Informado' }}</span>
                    <span class="desc-item row mx-1"><span class="label mr-1">Docente: </span>{{ conteudo.item.dctrsp || 'Não Informado' }}</span>
                    <span class="desc-item row mx-1"><span class="label mr-1">Departamento: </span>{{ conteudo.item.setste || 'Não Informado' }}</span>
                    <span class="desc-item row mx-1">
                      <span class="label mr-1">Vertente: </span>
                      {{ conteudo.item.vtesolprs?.texto ?? 'Não informado' }}
                      <small v-if="conteudo.item.vtesolprs?.getCodigo() === 'PESQUISA'">
                        ({{ conteudo.item.arecnhpsq?.texto }})
                      </small>
                    </span>
                  </div>
                  <div class="col-sm">
                    <span class="desc-item row mx-1"><span class="label mr-1">Situação: </span>{{ conteudo.item.sitprsatl }}</span>
                    <div class="data row mx-1">
                      <em class="far fa-clock mr-1 pt-1" />
                      Criação: {{ conteudo.item.dtacad }}
                    </div>
                    <div 
                      v-if="conteudo.item.dtaultalt"
                      class="data row mx-1"
                    >
                      <em class="far fa-clock mr-1 pt-1" />
                      Alteração: {{ conteudo.item.dtaultalt }}
                    </div>
                  </div>
                  <div class="col-lg-2">
                    <div class="buscar-acoes btn-detalhes">
                      <router-link
                        :to="{
                          name: 'Proserv:Detalhes',
                          params: { 
                            codsolprs: conteudo.item.codsolprs,
                            anosolprs: conteudo.item.anosolprs
                          },
                        }"
                        class="btn btn-sm btn-primary"
                      >
                        <em class="fa fa-folder-open" />
                        Detalhes
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </PortalCardPaginado>
    </div>
  </section>
</template>

<script>
import PortalCardPaginado from "@/portal/components/destaque/PortalCardPaginado.vue";
import ProServAtendimentoAviso from '../componentes/itens/ProServAtendimentoAviso.vue';
import { Actions, Getters } from "@/proserv/store/types";
import { mapActions, mapGetters } from "vuex";

  export default {
    name: 'ProServInicio',

    components: { PortalCardPaginado, ProServAtendimentoAviso },

    data(){
      return {
        carregandoDepartamentos: false,
        carregandoUnidades: false,
        codund: '',
        codset: '',
        filtro: '',
        filtroLabel: 'Filtrar processos por identificador, vertente, unidade, departamento ou docente',
        porPagina: 10,
        vertente: ''
      }
    },

    computed: {
      ...mapGetters({
        carregando: Getters.PROSERV.CARREGANDO_SOLICITACOES,
        lista: Getters.PROSERV.SOLICITACOES,
        unidades: Getters.PROSERV.UNIDADES,
        departamentos: Getters.PROSERV.DEPARTAMENTOS,
        vertentes: Getters.PROSERV.VERTENTES
      }),
      textoUnidades(){
        return this.carregandoUnidades ? 'Carregando...' : 'Todas'
      },
      textoSetores(){
        if(!this.codund) return 'Escolha uma unidade...'
        else if(this.carregandoDepartamentos) return 'Carregando...'
        return 'Todos'
      },
      listaFiltrada(){
        const self = this,
              filtro = self.filtro.toLowerCase(),
              ident = filtro.match(/#(\d+)/) ? filtro.match(/#(\d+)/)[1] : null;
        return this.filtro && this.filtro !== '#' ? 
                 this.lista.filter(p => {
                    if(ident) { return p.codsolprs == ident; }
                    else {
                     return p.vtesolprs.texto.toLowerCase().indexOf(filtro) !== -1 ||
                      p.undste.toString().toLowerCase().indexOf(filtro) !== -1 ||
                      (p.setste?.toString() ?? '').toLowerCase().indexOf(filtro) !== -1 ||
                      (p.dctrsp?.toString() ?? '').toLowerCase().indexOf(filtro) !== -1
                    }
                  }
                 ) : 
                 this.lista
      },
      filtroTextoHelp(){
        return `Mostrando ${this.listaFiltrada.length} solicitações do total de ${this.listaFiltrada.length}`
      },
    },

    mounted(){
      const self = this,
            promises = []
      if(self.vertentes.length === 0){
        promises.push(
          self.carregarVertentes()
        )
      }
      if(self.unidades.length === 0){
        self.carregandoUnidades = true
        promises.push(
          self.carregarUnidades()
              .then((unidades) => {
                if(unidades.length === 1){
                  self.codund = unidades[0].codund
                  self.listarDepartamentos()
                }
              })
              .finally(() => self.carregandoUnidades = false)
        )
      }

      promises.push(self.listarSolicitacoes())

      Promise.all(promises)
    },

    methods: {
      ...mapActions({
        carregarDepartamentos: Actions.PROSERV.CARREGAR_DEPARTAMENTOS,
        carregarUnidades: Actions.PROSERV.CARREGAR_UNIDADES,
        carregarVertentes: Actions.PROSERV.CARREGAR_VERTENTES,
        listarSolicitacoes: Actions.PROSERV.LISTAR_SOLICITACOES
      }),
      listarDepartamentos(){
        const self = this
        if(self.codund){
          self.carregandoDepartamentos = true
          self.carregarDepartamentos(self.unidades.find(u => u.codund === self.codund))
              .finally(() => self.carregandoDepartamentos = false)
        }
      },
      buscar(){
        const self = this
        self.listarSolicitacoes({
          codund: this.codund,
          vertente: this.vertente,
          codset: this.codset
        })
      }
    }

  }
</script>

<style lang="scss" scoped>

  div.buscar-acoes {
    display: flex;
    flex-flow: column;
    justify-content: space-around;

    button {
      margin: 0.3rem 0;
    }
  }

  div.solicitacao-item {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    div.titulo {
      color: #1094ab;
    }
    div.descricao {
      flex: 1;
      display: flex;
      flex-flow: column;

      .label {
        color: #1094ab;
        font-weight: 700;
      }

      small {
        align-self: center;
        font-style: italic;
        font-size: 0.75rem;
        margin: 0 .5rem;
      }

    }
    div.acoes {
      flex: 0 1 12rem;
      margin-left: auto;
    }
  }

  @media (max-width: 992px) {
    .btn-detalhes {
      margin-top: 0.7rem; 
    }
  }

</style>