<template>
  <div
    class="alert alert-info" 
    role="alert"
  >
    <p class="texto-aviso">
      <em class="fas fa-info"/>
      Em caso de dúvidas ou problemas, por favor entre em contato com 
      <a href="mailto:proserv-atendimento@usp.br">
        proserv-atendimento@usp.br
      </a>
    </p>
  </div>
</template>

<script>
  export default {
    name: 'ProServAtendimentoAviso'
  }
</script>

<style>
  .fa-info {
    margin: auto 20px;
  }

  .texto-aviso {
    margin: 0px;
  }
</style>