<template>
  <section>
    <div class="container-fluid">
      <uspCartao titulo="Acompanhamento Geral">
        <template #corpo>
          <form
            ref="form"
            class="form-inline p-2 mb-4"
            novalidate
            @submit.prevent=""
          >
            <h3 class="h5">
              Busca
            </h3>
            <label class="sr-only">Vertente</label>
            <select 
              v-model="vtesolprs"
              class="custom-select mx-3"
              name="vtesolprs"
              :disabled="salvando || divulgando"
              @change="listarSituacoes();"
            >
              <option 
                v-for="v in vertentes"
                :key="'vertente-'+v.valor"
                :value="v.valor"
              >
                {{ v.texto }}
              </option>
            </select>
            <label class="sr-only">Situação</label>
            <select 
              v-model="tipsitprs"
              name="tipsitprs"
              :disabled="salvando || divulgando"
              class="custom-select mx-3"
              @change="listarSolicitacoes()"
            >
              <option 
                v-for="s in situacoes"
                :key="'sitaucao-'+s.tipsitprs"
                :value="s.tipsitprs"
              >
                {{ s.dsctipsitprs }}
              </option>
            </select>
            <uspBotao
              v-if="botaoAprovar"
              v-show="!aprovacao"
              class="mx-2"
              :texto="botaoAprovar.texto"
              :variante="botaoAprovar.variante"
              :icone="botaoAprovar.icone"
              @clicar="botaoAprovar.clicar"
            />
            <uspBotao
              v-show="aprovacao"
              class="mx-2"
              texto="Salvar"
              variante="primario"
              icone="fas fa-save"
              :carregando="salvando"
              texto-carregando="Salvando..."
              :desabilitado="!avisoSalvar.correto || salvando"
              @clicar="salvar"
            />
            <uspBotao
              v-show="aprovacao"
              class="mx-2"
              texto="Cancelar"
              variante="perigo"
              icone="fas fa-ban"
              @clicar="aprovacao = false"
            />
            <p 
              v-show="aprovacao || podeDivulgar || (isPesquisa1 && !podeSalvar)"
              :class="avisoSalvar.classe"
            >
              {{ avisoSalvar.mensagem }}
            </p>
          </form>
          <table class="table table-responsive-md table-hover w-100">
            <caption>Lista de Aprovação com {{ lista.length }} solicitações</caption>
            <thead>
              <tr>
                <th v-if="aprovacao && !isPesquisa1" />
                <th>#</th>
                <th>Unidade</th>
                <th>Situação Atual</th>
                <th>
                  {{ textoAvaliador1 }}
                </th>
                <th v-if="!isPesquisa1">
                  Avaliador 2
                </th>
                <th v-if="!isPesquisa1">
                  Avaliador 3
                </th>
                <th v-if="!isPesquisa1">
                  Média
                </th>
                <th v-if="isPesquisa2">
                  Desvio Padrão
                </th>
              </tr>
            </thead>
            <tbody v-if="carregando">
              <tr>
                <td 
                  :colspan="8"
                >
                  <div class="td-carregando">
                    <div 
                      class="spinner-border m-5" 
                      role="status"
                    />
                    <span>Carregando...</span>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr
                v-for="item in lista"
                :key="item.codsolprs"
                :class="{
                  'table-success': aprovacao && isAprovado(item),
                  'table-danger': aprovacao && !isAprovado(item)
                }"
              >
                <td 
                  v-if="aprovacao && !isPesquisa1"
                  class="checktd"
                >
                  <input 
                    :id="'aprovacao-'+item.codsolprs"
                    v-model="aprovados"
                    type="checkbox"
                    name="aprovado"
                    :value="item.codsolprs"
                  >
                  <label 
                    :for="'aprovacao-'+item.codsolprs" 
                    class="sr-only"
                  >
                    Aprovado
                  </label>
                </td>
                <td class="identificador">
                  <span>{{ item.codsolprs }}/{{ item.anosolprs }}</span>
                  <router-link
                    v-show="!aprovacao"
                    :to="{
                      name: 'Proserv:Detalhes',
                      params: { 
                        codsolprs: item.codsolprs,
                        anosolprs: item.anosolprs
                      },
                    }"
                    target="_blank"
                    class="btn btn-sm btn-primary"
                  >
                    <em class="fa fa-folder-open" />
                    Detalhes
                  </router-link>
                </td>
                <td>{{ item.undste.sglund }}</td>
                <td>
                  {{ item.tipsitprsatl }}
                  {{ item.tipsitprsatl.tipsitprs.indexOf('REPR') != -1 ? `(${item.justificado ? 'Justificado' : 'Não Justificado'})` : '' }}
                </td>
                <td 
                  v-for="n in (!isPesquisa1 ? 3 : 1)"
                  :key="item.codsolprs+'-avaliador-'+n"
                >
                  <div 
                    v-if="item.avaliadores[n-1]"
                    class="item-avaliador"
                    :class="{ desconsiderado: item.avaliadores[n-1].stadcacalmed }"
                  >
                    <span class="nome">{{ item.avaliadores[n-1].nompesavd }}</span>
                    <span class="nota">
                      <span class="label">
                        {{ textoNota }}
                      </span>
                      <span class="nota">
                        {{ formatarNota(item.avaliadores[n-1].notaFinal) }}
                      </span>
                      <span class="outra-nota">
                        {{ textoOutraNota(item.avaliadores[n-1]) }}
                      </span>
                    </span>
                    <uspBotao
                      v-if="podeInverter(item, n-1)"
                      v-show="!aprovacao"
                      variante="perigo"
                      :carregando="desconsiderando === item.codsolprs+'-'+(n-1)"
                      texto-carregando="Invertendo..."
                      tamanho="pq"
                      :texto="item.avaliadores[n-1].stadcacalmed ? 'Desinverter' : 'Inverter'"
                      icone="fas fa-retweet"
                      @clicar="desconsiderar(item, n-1)"
                    />
                    <uspBotao
                      v-if="podeDesconsiderar(item, n-1)"
                      v-show="!aprovacao"
                      variante="perigo"
                      :carregando="desconsiderando === item.codsolprs+'-'+(n-1)"
                      texto-carregando="Desconsiderando..."
                      tamanho="pq"
                      texto="Desconsiderar"
                      icone="fas fa-ban"
                      @clicar="desconsiderar(item, n-1)"
                    />
                  </div>
                  <div
                    v-else
                    class="item-avaliador-vazio"
                  >
                    Não há
                  </div>
                </td>
                <td v-if="!isPesquisa1">
                  {{ item.media || '' }}
                </td>
                <td v-if="isPesquisa2">
                  {{ item.desvio !== null ? item.desvio : '' }}
                </td>
              </tr>
            </tbody>
          </table>
        </template>
      </uspCartao>
    </div>
  </section>
</template>

<script>
import ProServAprovacaoServico from '../dominio/aprovacao/ProServAprovacaoServico.js';
import ProServEstruturaServico from '../dominio/estrutura/ProServEstruturaServico.js';

  export default {
    name: 'ProServResultados',
    data(){
      return {
        api: ProServAprovacaoServico.build({}),
        estruturaApi: ProServEstruturaServico.build({}),
        aprovacao: false,
        salvando: false,
        erro: null,
        confirmando: false,
        desconsiderando: '',
        divulgando: false,
        textoBotao: 'Indicar Aprovados',
        carregando: false,
        lista: [],
        situacoes: [{ tipsitprs: '', dsctipsitprs: 'Carregando...' }],
        vertentes: [{ valor: '', texto: 'Carregando...' }],
        tipsitprs: '',
        vtesolprs: '',
        acaoConfimar: null,
        atencaoConfirmar: false,
        textoConfirmar: '',
        tituloConfirmar: '',
        aprovados: []
      }
    },
    computed: {
      avisoSalvar(){
        if(this.erro){
          return {
            classe: 'alert alert-danger',
            correto: false,
            mensagem: this.erro.detail
          }
        } else if(this.isPesquisa1 && this.semMedia > 0) {
          return { 
            classe: 'alert alert-danger',
            correto: false,
            mensagem: `Ainda restam ${this.semMedia} solicitações sem avaliação`
          }
        } else if(!this.isPesquisa1 && this.limiteAprovados < this.totalAprovados){
          return { 
            classe: 'alert alert-danger',
            correto: false,
            mensagem: `Devem ser aprovadas no máximo ${this.limiteAprovados} solicitações (escolhidas ${this.totalAprovados})`
          }
        } else if(this.podeDivulgar && this.naoJustificados > 0) {
          return { 
            classe: 'alert alert-danger',
            correto: false,
            mensagem: `Devem ser justificadas ${this.naoJustificados} solicitações não contempladas`
          }
        } else if(this.podeAprovar && this.aprovacao) {
          return {
            classe: 'alert alert-success',
            correto: true,
            mensagem: `Total de ${this.totalAprovados} solicitações aprovadas`
          } 
        } else if(this.isPesquisa1 && !this.podeSalvar) {
          return this.naoJustificados === 0 ? {} : {
            classe: 'alert alert-danger',
            correto: false,
            mensagem: `Devem ser justificadas ${this.naoJustificados} solicitações não contempladas`
          }
        } else {
          return {}
        }
      },
      botaoAprovar(){
        const self = this;
        if(self.isPesquisa1 && this.podeSalvar) {
          return {
            texto: 'Aprovar 1ª Fase',
            icone: 'fas fa-check',
            clicar(){
              if(self.semMedia === 0){
                self.aprovacao = true
              }
            }
          }
        } else if(this.podeSalvar) {
          return {
            texto: 'Indicar Aprovados',
            icone: 'fas fa-check',
            variante: 'secundario',
            clicar(){
              self.aprovacao = true
            }
          }
        } else if(this.podeDivulgar && !this.divulgando) {
          return {
            texto: 'Divulgar Resultados',
            icone: 'fas fa-newspaper',
            variante: 'info',
            clicar(){
              self.divulgar();
            }
          }
        } else if(this.podeDivulgar && this.divulgando) {
          return {
            texto: 'Finalizando',
            icone: 'fas fa-spinner fa-spin',
            variante: 'info'
          }
        } 
        return null
      },
      isNaoPesquisa(){
        return !this.isPesquisa1 && !this.isPesquisa2
      },
      isPesquisa2(){
        return this.tipsitprs === "AVAL_PRPI2"
      },
      isPesquisa1(){
        return this.tipsitprs === 'AVAL_PRPI'
      },
      naoJustificados(){
        return this.lista.filter(item => item.tipsitprsatl.tipsitprs.indexOf('REPR') !== -1 && !item.justificado).length;
      },
      semMedia(){
        return this.lista.filter(item => item.media === null).length;
      },
      podeAprovar(){
        return !this.carregando && this.lista.filter(item => item.tipsitprsatl.tipsitprs.indexOf('AVAL_') !== -1).length > 0
      },
      podeDivulgar(){
        return !this.carregando && 
                this.lista.filter(item => !['APROVADO', 'REPROVADO'].includes(item.tipsitprsatl.tipsitprs)).length > 0 &&
                this.tipsitprs !== 'AVAL_PRPI';
      },
      podeSalvar(){
        return !this.carregando && this.lista.findIndex(item => !item.leitura) !== -1;
      },
      textoNota(){
        return this.isPesquisa1 ?
              'Aprovado:': 
              'Nota:'
      },
      textoAvaliador1(){
        if (this.isPesquisa1) return 'Avaliador 1ª Fase'
        return 'Avaliador 1'
      },
      totalAprovados(){
        return this.aprovados.length;
      },
      limiteAprovados(){
        const self = this;
        return self.vertentes.find(v => v.valor === self.vtesolprs).limite
      }
    },
    watch: {
      aprovacao(apr){
        const self = this
        self.aprovados = []
        if(apr) {
          self.aprovados = []
          self.lista.forEach((item,idx) => {
            if(
              (!self.isPesquisa1 && idx < self.limiteAprovados) ||
              (self.isPesquisa1 && item.media === 1)
            ) {
              self.aprovados.push(item.codsolprs);
            }
          });
        }
      }
    },
    async mounted(){
      const self = this
      self.carregando = true
      self.listarVertentes()
    },
    methods: {
      isAprovado(item){
        return this.aprovados.indexOf(item.codsolprs) !== -1
      },
      atualizarItem(item, valor){
        item.aprovado = valor === 'on'
      },
      desconsiderar(item, indiceAvaliador){
        const self = this
        const codpesavd = item.avaliadores[indiceAvaliador].codpesavd
        self.desconsiderando = `${item.codsolprs}-${indiceAvaliador}`
        self.api.desconsiderarAprovacao(
          self.vtesolprs,
          item.anosolprs, 
          item.codsolprs, 
          codpesavd, 
          self.tipsitprs
        ).then(() => {
          self.listarSolicitacoes()
        })
        .catch(erro => self.erro = erro)
        .finally(() => self.desconsiderando = false)
      },
      divulgar(){
        const self = this;
        if(self.naoJustificados > 0) return
        self.divulgando = true
        self.api.finalizarAprovacoes(
          self.vtesolprs,
          self.tipsitprs
        ).then(() => self.listarSolicitacoes())
        .catch(erro => self.erro = erro)
        .finally(() => self.divulgando = false)
      },
      fecharModal(){
        const self = this
        self.tituloConfirmar = ''
        self.textoConfirmar = ''
        self.acaoConfimar = null
        self.atencaoConfirmar = false
        self.$refs.confirmar.closeModal()
      },
      listarSituacoes(){
        const self = this
        console.debug(`Listando as situações da vertente ${self.vtesolprs}`)
        self.situacoes = [{ tipsitprs: '', dsctipsitprs: 'Carregando...' }]
        self.tipsitprs = '';
        self.erro = null
        return self.estruturaApi.listarSituacoes(self.vtesolprs)
                       .then(lista => {
                          self.situacoes = lista
                          self.tipsitprs = lista[0]?.tipsitprs
                          self.listarSolicitacoes()
                       })
                       .catch(erro => self.erro = erro)
      },
      listarSolicitacoes(){
        const self = this
        console.debug(`Listando as solicitações da vertente ${self.vtesolprs} na situação ${self.tipsitprs}`)
        self.aprovacao = false
        self.carregando = true
        self.erro = null
        self.api.listarAprovacoes(self.vtesolprs, self.tipsitprs)
          .then(lista => self.lista = lista)
          .catch(erro => self.erro = erro)
          .finally(() => self.carregando = false)
      },
      listarVertentes(){
        const self = this
        self.erro = null
        console.debug('Listando as vertentes')
        return self.estruturaApi.listarVertentes()
                       .then(lista => { 
                          self.vertentes = lista
                          self.vtesolprs = lista[0]?.valor
                          self.listarSituacoes()
                       })
                       .catch(erro => self.erro = erro)
      },
      formatarNota(nota){
        if(nota === undefined || nota === null) return 'Não Avaliado'
        else if(this.isPesquisa1) return nota === 1 ? 'Aprovado': 'Não Aprovado'
        else return nota
      },
      podeDesconsiderar(avaliacao, indice){
        return !avaliacao.leitura &&
               avaliacao.avaliadores.filter(a => a.notaFinal).length > 2 && // Tem ao menos dois avaliadores com notas válidas
               !avaliacao.avaliadores[indice]?.stadcacalmed // Não foi desconisderado ainda
      },
      podeInverter(solicitacao, indice){
        return !solicitacao.leitura &&
               this.isPesquisa1 && 
               solicitacao.avaliadores[indice].nota != null;
      },
      textoOutraNota(avaliacao){
        if(!this.isPesquisa1 || !avaliacao.stadcacalmed) return ''
        else return this.formatarNota(avaliacao.notaFinal == 0 ? 1 : 0)
      },
      salvar(){
        const self = this;
        if(self.avisoSalvar.correto){
          self.salvando = true;
          const dados = {
            solicitacoes: self.lista.map(item => { return {
              anosolprs: item.anosolprs,
              codsolprs: item.codsolprs,
              aprovado: self.aprovados.indexOf(item.codsolprs) !== -1
            }})
          }
          self.api.aprovarSolicitacoes(
            self.vtesolprs,
            self.tipsitprs,
            dados
          ).then(() => self.listarSolicitacoes())
           .catch(erro => self.erro = erro)
           .finally(() => self.salvando = false)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

  th, td {
    text-align: center;
  }

  

  div.td-carregando {
    align-items: center;
    background-color: #CECECE;
    display: flex;
    flex-flow: row nowrap;
    font-weight: 900;
    font-size: 1.2rem;
    justify-content: center;
  }
  span.nome {
    font-weight: 600;
  }

  span.label {
    font-weight: 600;
    color: #1094ab;
  }

  span.nota {
    text-decoration: none;
  }

  span.nota-taxada {
    text-decoration: line-through;
  }

  span.outra-nota {
    text-decoration: none !important;
    font-weight: 700;
  }

  
  td.identificador {
    display: flex;
    flex-flow: column;
    font-weight: 800;
  }
  div.item-avaliador {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }
  
  div.item-avaliador.desconsiderado {
    text-decoration: line-through;
  }

  p.alert {
    margin: 0;
  }

</style>